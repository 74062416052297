/* import __COLOCATED_TEMPLATE__ from './templates-modal-button.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */

import Component from '@glimmer/component';
import type Guideline from 'embercom/models/ai-agent/guidelines';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type IntlService from 'ember-intl/services/intl';
import type AiAgentGuidelineService from 'embercom/services/ai-agent-guideline-service';
import type { GuidelineExample } from 'embercom/services/ai-agent-guideline-service';
import type { GuidelineCategory } from 'embercom/models/ai-agent/guidelines';
export interface Args {
  category: GuidelineCategory;
  guideline?: Guideline;
  openAccordionSection?: (guideline: Guideline) => void;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

export default class TemplatesModalButton extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare aiAgentGuidelineService: AiAgentGuidelineService;
  @service declare intercomEventService: $TSFixMe;
  @tracked showModal = false;

  guideline: Guideline | undefined;

  constructor(owner: any, args: Args) {
    super(owner, args);
    this.guideline = this.args.guideline;
  }

  get guidelineExamples(): GuidelineExample[] {
    return this.aiAgentGuidelineService.guidelineExamples(this.args.category);
  }

  get attributeInfoResolver() {
    return this.aiAgentGuidelineService.attributeInfoResolver();
  }

  @action
  showModalOnClick() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'templates_modal_button',
      place: 'templates_area',
      section: 'guidance',
    });
    this.showModal = true;
  }

  @action
  getSerializedBlocks(prompt: string) {
    return this.aiAgentGuidelineService.serializedBlocksFromPrompt(prompt);
  }

  @action
  addExampleGuideline(example: GuidelineExample) {
    this.showModal = false;

    // add from guideline-card empty-state (with guideline)
    if (this.args.guideline) {
      this.aiAgentGuidelineService.handleAddFromExampleGuidelineEmptyState(
        this.args.guideline,
        example,
      );
    } else if (this.args.openAccordionSection) {
      // add from collapsible-list empty state (no guideline)
      this.guideline = this.aiAgentGuidelineService.handleAddFromExampleCategoryEmptyState(
        this.args.category,
        example,
      );

      this.args.openAccordionSection(this.guideline);
    }
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'template_card',
      place: 'templates_modal',
      section: 'guidance',
      template_title: example.title,
      template_category: this.args.category,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Guidance::TemplatesModalButton': typeof TemplatesModalButton;
  }
}
