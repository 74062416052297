/* import __COLOCATED_TEMPLATE__ from './settings.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';
import type Guideline from 'embercom/models/ai-agent/guidelines';
import type IntlService from 'embercom/services/intl';
import type RouterService from '@ember/routing/router-service';
import { tracked } from '@glimmer/tracking';
import type Store from '@ember-data/store';

const SECTION_MODEL_MAP: any = {
  'suggestion-settings': 'aiAgentSettings',
  'answer-bot': '',
};

// https://app.intercom.com/a/apps/tx2p130c/outbound/survey/35658277

interface SettingsArgs {
  initialSection: string;
  aiAgentQuickReplySettings: any;
  aiAgentSettings: any;
  aiAgentToneOfVoiceSettings: any;
  aiAgentMultilingualSettings: any;
  previewConfiguration: any;
  completeConversationImportFlow: any;
  languages: any;
}

export default class Settings extends Component<SettingsArgs> {
  @service declare appService: any;
  @service declare intercomEventService: any;
  @service declare notificationsService: any;
  @service declare permissionsService: any;
  @service declare intercomConfirmService: IntercomConfirmService;
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare router: RouterService;
  @service declare customerService: $TSFixMe;

  @tracked showAiAgentImportSettingsModal = false;
  @tracked conversationImports: any;
  @tracked personalityCustomizationSettings?: Settings;
  constructor(owner: unknown, args: any) {
    super(owner, args);
    this.conversationImports = this.store.findAll('conversation-import');
    this.getPersonalityCustomizationSettings();
  }

  async getPersonalityCustomizationSettings(): Promise<$TSFixMe> {
    this.personalityCustomizationSettings = await this.store.findRecord(
      'ai-agent/tone-of-voice-settings',
      this.appService.app.id,
    );
  }

  get guidelines(): Guideline[] {
    return this.store.peekAll('ai-agent/guidelines').toArray();
  }
  get app() {
    return this.appService.app;
  }

  get hasActiveZendeskChatConversationImport() {
    return this.conversationImports.any(
      (conversationImport: any) =>
        conversationImport.active && conversationImport.origin === 'zendesk_chat',
    );
  }

  get hasActiveZendeskTicketsConversationImport() {
    return this.conversationImports.any(
      (conversationImport: any) =>
        conversationImport.active && conversationImport.origin === 'zendesk_tickets',
    );
  }

  get manageUsageHref() {
    return this.router.urlFor(
      this.customerService.onPricing5_XOrFinForPlatforms
        ? 'apps.app.settings.subscription.usage'
        : 'apps.app.billing.manage-usage',
      this.appService.app.id,
    );
  }

  get pricing5CustomerWithSuggestionsTurnedOn() {
    if (!this.appService.app.canUseFeature('show-fin-suggestion-settings-to-p5-customers')) {
      return false;
    }

    let settings = this.args.aiAgentSettings;

    if (settings.hasDirtyAttributes) {
      return true;
    }

    return (
      (this.appService.app.usesResolutionsWithCustomAnswers &&
        settings.contentSuggestionsEnabledForUsers) ||
      settings.contentSuggestionsEnabledForVisitors ||
      settings.predictiveAnswersEnabledForUsers ||
      settings.predictiveAnswersEnabledForVisitors
    );
  }

  get shouldShowConfirmBeforeSave() {
    let settings = this.args.aiAgentSettings;

    if (
      this.appService.app.usesResolutionsWithCustomAnswers &&
      !settings.contentSuggestionsEnabledForUsers &&
      !settings.contentSuggestionsEnabledForVisitors &&
      !settings.predictiveAnswersEnabledForUsers &&
      !settings.predictiveAnswersEnabledForVisitors
    ) {
      return true;
    }
    return false;
  }

  @action
  async confirmBeforeSaving() {
    if (!this.shouldShowConfirmBeforeSave) {
      return true;
    }

    let confirmOptions = {
      title: this.intl.t('ai-agent.settings.custom-answer-suggestions.disable-modal.title'),
      body: this.intl.t('ai-agent.settings.custom-answer-suggestions.disable-modal.body'),
      confirmButtonText: this.intl.t(
        'ai-agent.settings.custom-answer-suggestions.disable-modal.confirm',
      ),
      cancelButtonText: this.intl.t(
        'ai-agent.settings.custom-answer-suggestions.disable-modal.cancel',
      ),
      primaryButtonType: 'primary-destructive',
    };

    let confirmed = await this.intercomConfirmService.confirm(confirmOptions);
    if (!confirmed) {
      this.args.aiAgentSettings.rollbackAttributes();
      throw new Error();
    }
    return true;
  }

  @action sectionDidOpen(sectionName: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened',
      context: 'operator_settings',
      object: `${sectionName}-section`,
    });
  }

  @action sectionDidClose(sectionName: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'closed',
      context: 'operator_settings',
      object: `${sectionName}-section`,
    });
  }

  @action async sectionCanClose(sectionName: string) {
    let model = SECTION_MODEL_MAP[sectionName];
    let confirmNotSaved = {
      title: this.intl.t('operator.settings.confirm-not-saved.close-without-saving'),
      primaryButtonType: 'primary-destructive',
      confirmButtonText: this.intl.t('operator.settings.confirm-not-saved.continue-without-saving'),
      cancelButtonText: this.intl.t('operator.settings.confirm-not-saved.keep-editing'),
      body: this.intl.t('operator.settings.confirm-not-saved.body'),
    };

    if (!model || !model.hasDirtyAttributes) {
      return true;
    }

    let confirmed = await this.intercomConfirmService.confirm(confirmNotSaved);

    if (confirmed) {
      model.rollbackAttributes();
      return true;
    } else {
      return false;
    }
  }

  @action openAiAgentImportSettingsModal() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'import_conversation_history_link',
      context: 'zendesk_chat_import',
    });
    this.showAiAgentImportSettingsModal = true;
  }

  @action closeAiAgentImportSettingsModal() {
    this.showAiAgentImportSettingsModal = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Settings': typeof Settings;
  }
}
