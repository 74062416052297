/* import __COLOCATED_TEMPLATE__ from './question-generation-banner.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent-2 */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { postRequest, ResponseError } from 'embercom/lib/inbox/requests';
import {
  type PlaygroundQuestionGenerationJobWireFormat,
  QuestionGenerationStatus,
} from 'embercom/lib/fin-playground/question-generation';

interface QuestionGenerationBannerArgs {
  status?: QuestionGenerationStatus;
  requiresUserAcknowledgement?: boolean;
  onResetLatestJobData: () => void;
  onUpdateLatestJobData: (json: PlaygroundQuestionGenerationJobWireFormat) => void;
}

type QuestionGenerationBannerSignature = {
  Args: QuestionGenerationBannerArgs;
};

export default class QuestionGenerationBanner extends Component<QuestionGenerationBannerSignature> {
  @service declare appService: $TSFixMe;
  @service declare intl: $TSFixMe;
  @service declare notificationsService: $TSFixMe;

  get shouldShowBanner(): boolean {
    return (
      this.isRunning || this.needsFailureAcknowledgement || this.needsEmptyResultAcknowledgement
    );
  }

  get isRunning(): boolean {
    return this.args.status === QuestionGenerationStatus.Running;
  }

  get needsEmptyResultAcknowledgement(): boolean {
    return (
      this.args.status === QuestionGenerationStatus.EmptyResult &&
      this.args.requiresUserAcknowledgement === true
    );
  }

  get needsFailureAcknowledgement(): boolean {
    return (
      this.args.status === QuestionGenerationStatus.Failed &&
      this.args.requiresUserAcknowledgement === true
    );
  }

  @action
  cancelQuestionGeneration() {
    taskFor(this._cancelQuestionGenerationTask).perform();
  }

  @action
  hideQuestionGenerationBanner() {
    taskFor(this._acknowledgeQuestionGenerationJob).perform();
  }

  @dropTask
  *_cancelQuestionGenerationTask() {
    try {
      yield postRequest(
        `/ember/fin_playground/cancel_question_generation?app_id=${this.appService.app.id}`,
      );

      this.args.onResetLatestJobData();
    } catch (error) {
      // If there is no job to cancel we will receive 409
      if (error instanceof ResponseError && error.response.status === 409) {
        this.args.onResetLatestJobData();
      } else {
        this.notificationsService.notifyError(
          this.intl.t('ai-agent.playground.banners.generating-questions.cancel-error'),
        );
      }
    }
  }

  @dropTask
  *_acknowledgeQuestionGenerationJob() {
    try {
      let response = (yield postRequest(
        `/ember/fin_playground/acknowledge_question_generation_job?app_id=${this.appService.app.id}`,
      )) as Response;

      let json = (yield response.json()) as PlaygroundQuestionGenerationJobWireFormat;
      this.args.onUpdateLatestJobData(json);
    } catch (error) {
      // If there is no job to acknowledge we will receive 409
      if (error instanceof ResponseError && error.response.status === 409) {
        this.args.onResetLatestJobData();
      } else {
        this.notificationsService.notifyError(
          this.intl.t('ai-agent.playground.banners.generating-questions.acknowledge-error'),
        );
      }
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground::QuestionGenerationBanner': typeof QuestionGenerationBanner;
  }
}
