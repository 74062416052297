/* import __COLOCATED_TEMPLATE__ from './question-table.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent-2 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { action } from '@ember/object';
import {
  type PlaygroundQuestion,
  CustomerAnswerRating,
  Status,
} from 'embercom/lib/fin-playground/question';
import { tracked } from '@glimmer/tracking';

interface Args {
  questions: PlaygroundQuestion[];
  selectedQuestion: PlaygroundQuestion;
  selectedQuestionIndex: number;
  onRowClick: (questionText: string) => void;
  bulkSelectedQuestions: PlaygroundQuestion[];
  isDetailsModuleOpen: boolean;
}

export default class QuestionTable extends Component<Args> {
  @service declare intl: IntlService;
  @tracked hoveredRowIndex: number | null = null;

  stamp = (
    questionStatus: Status,
    questionAnswerRating?: CustomerAnswerRating,
  ): { text: string; color: 'red' | 'green' | 'gray' } | null => {
    if (questionStatus === Status.Completed) {
      switch (questionAnswerRating) {
        case CustomerAnswerRating.Positive:
          return {
            text: this.intl.t(`ai-agent.playground.answer-rating.positive`),
            color: 'green',
          };
        case CustomerAnswerRating.Negative:
          return {
            text: this.intl.t(`ai-agent.playground.answer-rating.negative`),
            color: 'red',
          };
        default:
          // ie, the rating is null
          return {
            text: this.intl.t(`ai-agent.playground.answer-rating.needs-rating`),
            color: 'gray',
          };
      }
    }

    // otherwise (eg running), don't show a stamp
    return null;
  };

  get areAllNonRunningQuestionsSelected() {
    return this.args.questions.every(
      (question) => question.status === 'running' || question.isBulkSelected,
    );
  }

  get areAllQuestionsRunning() {
    return this.args.questions.every((question) => question.status === 'running');
  }

  @action
  handleSelect(question: PlaygroundQuestion, event: Event) {
    event.stopPropagation();
    question.isBulkSelected = !question.isBulkSelected;
  }

  @action
  onBulkSelect(event: Event) {
    event.stopPropagation();

    if (this.areAllNonRunningQuestionsSelected) {
      this.resetBulkSelect();
    } else if (this.args.bulkSelectedQuestions.length >= 0) {
      this.args.questions.forEach((question) => {
        question.isBulkSelected = true;
      });
    }
  }

  @action
  resetBulkSelect() {
    this.args.questions.forEach((question) => {
      question.isBulkSelected = false;
    });
  }

  @action
  setHoveredIndex(index: number | null) {
    this.hoveredRowIndex = index;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground::QuestionTable': typeof QuestionTable;
  }
}
