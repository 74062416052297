/* import __COLOCATED_TEMPLATE__ from './empty-state.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import templateOnlyComponent from '@ember/component/template-only';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

export interface EmptyStateArgs {
  title: string;
  description: string;
  icon: InterfaceIconName;
}

interface Signature {
  Args: EmptyStateArgs;
  Blocks: {
    default: [];
  };
  Element: HTMLElement;
}
const EmptyState = templateOnlyComponent<Signature>();
export default EmptyState;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::VoicePlayground::EmptyState': typeof EmptyState;
  }
}
