/* import __COLOCATED_TEMPLATE__ from './admin-email-alias-side-drawer.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { put } from 'embercom/lib/ajax';
import { getAdminProfile } from 'embercom/lib/admins/admin-profiles';
import type InboxApi from 'embercom/services/inbox-api';
import type IntlService from 'ember-intl/services/intl';
import type Store from '@ember-data/store';
import { taskFor } from 'ember-concurrency-ts';
import { restartableTask } from 'ember-concurrency-decorators';
import type Session from 'embercom/services/session';
import type SenderEmailAddressSummary from 'embercom/objects/inbox/sender-email-address-summary';

interface Args {
  isOpen: boolean;
  onClose: () => void;
  aliasSenderEmailAddressSettingsId: string;
  aliasName: string;
}

interface SenderAddressItem {
  value: string | number;
  text: string;
  component: string;
  componentAttrs?: {
    verified?: boolean;
    isSelected?: boolean;
    isAuthenticated?: any;
  };
  isDisabled?: boolean;
}

export default class AdminEmailAliasSideDrawer extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare inboxApi: InboxApi;
  @service intercomEventService: $TSFixMe;
  @service intl!: IntlService;
  @service declare notificationsService: $TSFixMe;
  @service declare store: Store;
  @service declare session: Session;

  @tracked showAddCustomSenderModal = false;

  @tracked selectedSenderEmailAddressSettingsId?: string | number;
  @tracked emailSenders: SenderEmailAddressSummary[] = [];

  declare model: any;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    taskFor(this.loadSendersTask).perform();
  }

  get addressItems(): SenderAddressItem[] {
    if (!this.emailSenders?.length) {
      return [
        {
          value: 0,
          text: '',
          component: 'new-settings/channels/email/advanced/email-dropdown-empty-list',
          isDisabled: true,
        },
      ];
    }

    return this.emailSenders.map((address: SenderEmailAddressSummary) => ({
      value: address.senderEmailAddressSettingsId ?? 0,
      text: address.email ?? '',
      isDisabled: this.isItemDisabled(address),
      component: 'new-settings/workspace/brands/brand-email-dropdown-item',
      componentAttrs: {
        verified: address.verified,
        id: address.senderEmailAddressSettingsId,
        isSelected:
          this.selectedSenderEmailAddressSettingsId === address.senderEmailAddressSettingsId,
        isAuthenticated: address.dmarcValid,
      },
    }));
  }

  get hideWarning(): boolean {
    return taskFor(this.loadSendersTask).isRunning || this.selectedItem !== undefined;
  }

  get selectedItem() {
    return this.selectedSenderEmailAddressSettingsId;
  }

  get app() {
    return this.appService.app;
  }

  get hasPermissionForCustomAddresses() {
    return this.app.currentAdmin.currentAppPermissions.can_send_from_custom_addresses;
  }

  isItemDisabled(address: SenderEmailAddressSummary) {
    return !address.dmarcValid || !address.verified;
  }

  @action
  updateSelectedEmail(id: string | undefined) {
    this.selectedSenderEmailAddressSettingsId = id;
  }

  @action
  async saveAliasEmail() {
    try {
      let data = {
        app_id: this.app.id,
        id: this.app.currentAdmin.id,
        sender_email_address_settings_id: this.selectedSenderEmailAddressSettingsId,
        alias_enabled: true,
        alias_name: this.args.aliasName,
      };

      await put(`/ember/admins/${this.app.currentAdmin.id}`, data);

      let adminProfile = await getAdminProfile(this.store, this.app.id, this.app.currentAdmin.id);
      this.app.currentAdmin.set('profile', adminProfile);

      await this.session.getTeammate(this.app.id);

      this.args.onClose();
      this.notificationsService.notifyConfirmation(
        this.intl.t('components.admin.about-component.alias.email.changes-saved'),
      );
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t('components.admin.about-component.alias.email.not-updated'),
      );
    }
  }

  @action
  clearAliasEmail() {
    this.selectedSenderEmailAddressSettingsId = undefined;
  }

  @action
  toggleDisplayCustomSenderModal() {
    this.showAddCustomSenderModal = !this.showAddCustomSenderModal;
    if (this.showAddCustomSenderModal) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'create_custom_sender_address',
        context: 'from_admin_about_profile',
      });
    }
  }

  @restartableTask
  *loadSendersTask() {
    this.emailSenders = yield this.inboxApi.fetchSenderEmailAddresses();

    // The end point we are using is also power the senders list in the composer,
    // and can have duplicates where a single sender email can have different display names.
    // For example, "John support@example.com" and "Sarah support@example.com".
    // We need dedupe senders on sender_email_address_settings_id
    let distinctEmailSenders = new Map(
      this.emailSenders.map((sender) => [sender.senderEmailAddressSettingsId, sender]),
    );
    this.emailSenders = Array.from(distinctEmailSenders.values());
    let storedSelection = this.args.aliasSenderEmailAddressSettingsId?.toString();

    let matchingItem = this.addressItems.find((item) => item.value.toString() === storedSelection);
    if (matchingItem) {
      this.selectedSenderEmailAddressSettingsId = matchingItem.value;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Admin::AdminEmailAliasSideDrawer': typeof AdminEmailAliasSideDrawer;
    'admin/admin-email-alias-side-drawer': typeof AdminEmailAliasSideDrawer;
  }
}
