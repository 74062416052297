/* import __COLOCATED_TEMPLATE__ from './question-filters-and-actions.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent-2 */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import Component from '@glimmer/component';
import { type Playground } from 'embercom/lib/fin-playground/playground';
import {
  type PlaygroundQuestion,
  CustomerAnswerRating,
  AnswerState,
} from 'embercom/lib/fin-playground/question';

interface QuestionFiltersAndActionsArgs {
  questions: PlaygroundQuestion[];
  filteredQuestions: PlaygroundQuestion[];
  isPlaygroundActive: boolean;
  updateQuestionRatingFilter: (rating: CustomerAnswerRating | null) => void;
  updateAnswerTypeFilter: (filter: AnswerState | null) => void;
  questionFilters: Playground['questionFilters'];
  setQuestionIndex: (index: number) => void;
  selectedQuestionId?: number;
  areQuestionsBulkSelected: boolean;
  countOfFilteredQuestions: number;
  countOfBulkSelectedQuestions: number;
  updateShowConfirmTestRunModal: (showConfirmTestRunModal: boolean) => void;
  updateShowConfirmDeleteQuestionsModal: (showConfirmDeleteQuestionsModal: boolean) => void;
}

type QuestionFiltersAndActionsSignature = {
  Args: QuestionFiltersAndActionsArgs;
  Element: HTMLDivElement;
  Blocks: {
    default: [];
  };
};

export default class QuestionFiltersAndActions extends Component<QuestionFiltersAndActionsSignature> {
  @service declare intl: IntlService;

  CustomerAnswerRating = CustomerAnswerRating;
  AnswerState = AnswerState;

  @action selectAnswerState(value: AnswerState | null) {
    this.args.updateAnswerTypeFilter(value);
  }

  @action selectAnswerRating(value: CustomerAnswerRating | null) {
    this.args.updateQuestionRatingFilter(value);
  }

  get answerStateGroupList() {
    return [
      {
        items: [
          {
            text: this.intl.t('ai-agent.playground.filters.answer-state.group-list.all'),
            value: null,
          },
          {
            text: this.intl.t('ai-agent.playground.filters.answer-state.group-list.answered'),
            value: AnswerState.Answered,
          },
          {
            text: this.intl.t('ai-agent.playground.filters.answer-state.group-list.unanswered'),
            value: AnswerState.Unanswered,
          },
        ],
      },
    ];
  }

  get answerStateInfo() {
    switch (this.args.questionFilters.answerTypeFilter) {
      case AnswerState.Answered:
        return {
          name: this.intl.t('ai-agent.playground.filters.answer-state.name'),
          label: this.intl.t('ai-agent.playground.filters.answer-state.answered'),
        };
      case AnswerState.Unanswered:
        return {
          name: this.intl.t('ai-agent.playground.filters.answer-state.name'),
          label: this.intl.t('ai-agent.playground.filters.answer-state.unanswered'),
        };
      default:
        return {
          name: this.intl.t('ai-agent.playground.filters.answer-state.name'),
          label: this.intl.t('ai-agent.playground.filters.answer-state.all'),
        };
    }
  }

  get answerRatingGroupList() {
    return [
      {
        items: [
          {
            text: this.intl.t('ai-agent.playground.filters.answer-rating.group-list.any'),
            value: null,
          },
          {
            text: this.intl.t('ai-agent.playground.filters.answer-rating.group-list.positive'),
            value: CustomerAnswerRating.Positive,
          },
          {
            text: this.intl.t('ai-agent.playground.filters.answer-rating.group-list.negative'),
            value: CustomerAnswerRating.Negative,
          },
        ],
      },
    ];
  }

  get answerRatingDetails() {
    switch (this.args.questionFilters.ratingFilter) {
      case CustomerAnswerRating.Positive:
        return this.intl.t('ai-agent.playground.filters.answer-rating.group-list.positive');
      case CustomerAnswerRating.Negative:
        return this.intl.t('ai-agent.playground.filters.answer-rating.group-list.negative');
      default:
        return this.intl.t('ai-agent.playground.filters.answer-rating.group-list.any');
    }
  }

  get areFiltersApplied(): boolean {
    return (
      this.args.questionFilters.ratingFilter !== null ||
      this.args.questionFilters.answerTypeFilter !== null
    );
  }

  @action updateSelectedQuestionIndex(currentlySelectedQuestionId?: number) {
    let newIndexOfSelectedQuestion = this.args.filteredQuestions.findIndex(
      (question: PlaygroundQuestion) => question.id === currentlySelectedQuestionId,
    );

    newIndexOfSelectedQuestion !== -1
      ? this.args.setQuestionIndex(newIndexOfSelectedQuestion)
      : this.args.setQuestionIndex(0);
  }

  @action updateQuestionRatingFilter(rating: CustomerAnswerRating) {
    let currentlySelectedQuestionId = this.args.selectedQuestionId;
    this.args.updateQuestionRatingFilter(rating);

    this.updateSelectedQuestionIndex(currentlySelectedQuestionId);
  }

  @action updateAnswerTypeFilter(filter: AnswerState) {
    let currentlySelectedQuestionId = this.args.selectedQuestionId;
    this.args.updateAnswerTypeFilter(filter);

    this.updateSelectedQuestionIndex(currentlySelectedQuestionId);
  }

  @action clearFilters() {
    this.args.updateQuestionRatingFilter(null);
    this.args.updateAnswerTypeFilter(null);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground::QuestionFiltersAndActions': typeof QuestionFiltersAndActions;
  }
}
