/* import __COLOCATED_TEMPLATE__ from './header-controls.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import templateOnlyComponent from '@ember/component/template-only';

export interface ConnectionControlsArgs {
  hasSessions: boolean;
  verboseLogsEnabled: boolean;
  copyLatestSessionId: () => Promise<void>;
  toggleVerboseLogsEnabled: () => void;
  reportIssue: () => void;
}

interface Signature {
  Args: ConnectionControlsArgs;
  Element: HTMLElement;
}

const HeaderControls = templateOnlyComponent<Signature>();
export default HeaderControls;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::VoicePlayground::HeaderControls': typeof HeaderControls;
  }
}
