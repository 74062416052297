/* import __COLOCATED_TEMPLATE__ from './language-support-message.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent-2 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import type RouterService from '@ember/routing/router-service';
import type LanguagesService from 'embercom/services/languages-service';
import { action } from '@ember/object';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { ANSWER_STATE_ANSWER_TYPE_MAP, AnswerState, Status } from 'embercom/lib/fin-playground';

interface LanguageSupportMessageArgs {
  questionDetectedLocale?: string;
  detectedLocale?: string;
  answerType?: string;
  status?: string;
  questionFallbackSearchLocale?: string;
  onSettingsClick?: () => void;
  onRerun?: () => void;
}

/**
 * Interface for message parts to build structured messages with text and links
 */
interface MessagePart {
  type: 'text' | 'link';
  content: string;
  action?: string;
  params?: Record<string, unknown>;
}

/**
 * Component that displays different messages based on language detection and support status.
 * Shows appropriate messages with action links for language support and RTT.
 */
export default class LanguageSupportMessage extends Component<LanguageSupportMessageArgs> {
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare languagesService: LanguagesService;

  @action
  addMoreLanguages() {
    safeWindowOpen(this.router.urlFor('apps.app.settings.workspace.general'), '_blank');
  }

  @action
  goToSettings() {
    safeWindowOpen(
      this.router.urlFor('apps.app.automation.fin-ai-agent.settings', {
        queryParams: {
          section: 'multilingual-settings',
        },
      }),
      '_blank',
    );
  }

  @action
  handleLinkClick(action: string) {
    if (action === 'goToSettings') {
      this.goToSettings();
    } else if (action === 'addMoreLanguages') {
      this.addMoreLanguages();
    } else if (action === 'onRerun' && this.args.onRerun) {
      this.args.onRerun();
    }
  }

  get isRttEnabled() {
    return this.languagesService.multilingualSettings?.fallbackSearchLocaleEnabled === true;
  }

  get isLanguageSettingsLoading() {
    return (
      this.languagesService.isLanguagesLoading ||
      this.languagesService.isMultilingualSettingsLoading
    );
  }

  get shouldShowLanguageSupportBanner(): boolean {
    // Dont show banner when:
    // 1. Question is running
    if (this.args.status === Status.Running) {
      return false;
    }

    // 2. questionDetectedLocale is undefined or empty
    if (!this.args.questionDetectedLocale) {
      return false;
    }

    // 3. Language Settings are loading
    if (this.isLanguageSettingsLoading) {
      return false;
    }

    // 4. Question's detected locale is the default locale
    if (
      this.languagesService.languages?.defaultLocale &&
      this.args.questionDetectedLocale === this.languagesService.languages.defaultLocale
    ) {
      return false;
    }

    // Show banner when:
    // 1. There are different detected locales
    // 2. Question is out of domain and RTT is disabled
    // 3. RTT enabled but questionFallbackSearchLocale is nil

    return (
      this.shouldShowLanguageMismatchMessage ||
      this.isOutOfDomainWithRttDisabled ||
      this.shouldShowRttEnabledMessage
    );
  }

  get shouldShowLanguageMismatchMessage() {
    return (
      this.args.questionDetectedLocale &&
      this.args.detectedLocale &&
      this.args.questionDetectedLocale !== this.args.detectedLocale
    );
  }

  get shouldShowRttDisabledMessage() {
    return (
      !this.isRttEnabled &&
      ANSWER_STATE_ANSWER_TYPE_MAP[AnswerState.Unanswered].includes(this.args.answerType || '')
    );
  }

  get shouldShowRttEnabledMessage() {
    let questionHasNoFallbackLocale = !this.args.questionFallbackSearchLocale;

    return this.isRttEnabled && questionHasNoFallbackLocale;
  }

  get isLanguageNowSupported() {
    let { questionDetectedLocale = '' } = this.args;

    if (!this.languagesService.languages?.supportedLocales) {
      return false;
    }

    let supportedLanguages = this.languagesService.languages.supportedLocales
      .filter((locale) => locale.isPermitted)
      .map((locale) => locale.localeId);

    if (!Array.isArray(supportedLanguages)) {
      return false;
    }

    return supportedLanguages.includes(questionDetectedLocale);
  }

  get languageName() {
    let { questionDetectedLocale = '' } = this.args;
    return this.languagesService.getLanguageName(questionDetectedLocale);
  }

  get messageType(): 'supported' | 'unsupported' | 'rttDisabled' | 'rttEnabled' | null {
    if (this.shouldShowLanguageMismatchMessage) {
      return this.isLanguageNowSupported ? 'supported' : 'unsupported';
    }

    if (this.shouldShowRttDisabledMessage) {
      return 'rttDisabled';
    }

    if (this.shouldShowRttEnabledMessage) {
      return 'rttEnabled';
    }

    return null;
  }

  get messageParts(): MessagePart[] | null {
    let messageBuilders = {
      supported: this.buildSupportedLanguageMessage,
      unsupported: this.buildUnsupportedLanguageMessage,
      rttDisabled: this.buildRttNotEnabledMessage,
      rttEnabled: this.buildRttEnabledMessage,
    };

    return this.messageType ? messageBuilders[this.messageType].call(this) : null;
  }

  /**
   * Builds message parts for supported language scenario
   */
  buildSupportedLanguageMessage(): MessagePart[] {
    // Start with language support enabled text
    let parts: MessagePart[] = [
      {
        type: 'text',
        content: `${this.intl.t(
          'ai-agent.playground.fin-answer.language-support-message.supported-language',
          { language: this.languageName },
        )} `,
      },
    ];

    if (!this.isRttEnabled) {
      // Add "Enable RTT to translate..." message
      parts.push(
        {
          type: 'link',
          content: this.intl.t(
            'ai-agent.playground.fin-answer.language-support-message.enable-rtt',
          ),
          action: 'goToSettings',
        },
        {
          type: 'text',
          content: this.intl.t(
            'ai-agent.playground.fin-answer.language-support-message.translate-knowledge-prompt',
          ),
        },
      );

      // Add rerun link if callback exists
      if (this.args.onRerun) {
        parts.push(
          {
            type: 'link',
            content: this.intl.t('ai-agent.playground.fin-answer.language-support-message.re-run'),
            action: 'onRerun',
          },
          {
            type: 'text',
            content: this.intl.t(
              'ai-agent.playground.fin-answer.language-support-message.test-to-get-answers',
              { language: this.languageName },
            ),
          },
        );
      } else {
        parts.push({
          type: 'text',
          content: this.intl.t(
            'ai-agent.playground.fin-answer.language-support-message.re-run-test-to-get-answers',
            { language: this.languageName },
          ),
        });
      }
    } else if (this.args.onRerun) {
      // Add "Re-run the test..." message
      parts.push(
        {
          type: 'link',
          content: this.intl.t(
            'ai-agent.playground.fin-answer.language-support-message.re-run-capitalized',
          ),
          action: 'onRerun',
        },
        {
          type: 'text',
          content: this.intl.t(
            'ai-agent.playground.fin-answer.language-support-message.test-to-get-answers',
            { language: this.languageName },
          ),
        },
      );
    } else {
      parts.push({
        type: 'text',
        content: this.intl.t(
          'ai-agent.playground.fin-answer.language-support-message.re-run-test-to-get-answers',
          { language: this.languageName },
        ),
      });
    }

    return parts;
  }

  /**
   * Builds message parts for unsupported language scenario
   */
  buildUnsupportedLanguageMessage(): MessagePart[] {
    // Start with unsupported language text
    let parts: MessagePart[] = [
      {
        type: 'text',
        content: `${this.intl.t(
          'ai-agent.playground.fin-answer.language-support-message.unsupported-language',
          { language: this.languageName },
        )} `,
      },
      {
        type: 'link',
        content: this.intl.t('ai-agent.playground.fin-answer.language-support-message.enable-it'),
        action: 'addMoreLanguages',
      },
    ];

    if (this.isRttEnabled) {
      parts.push({
        type: 'text',
        content: this.intl.t(
          'ai-agent.playground.fin-answer.language-support-message.rtt-help-translation',
          { language: this.languageName },
        ),
      });
    } else {
      parts.push({
        type: 'text',
        content: this.intl.t('ai-agent.playground.fin-answer.language-support-message.along-with'),
      });
      parts.push({
        type: 'link',
        content: this.intl.t('ai-agent.playground.fin-answer.language-support-message.rtt'),
        action: 'goToSettings',
      });
      parts.push({
        type: 'text',
        content: this.intl.t(
          'ai-agent.playground.fin-answer.language-support-message.translate-into-language',
          { language: this.languageName },
        ),
      });
    }

    return parts;
  }

  /**
   * Builds message parts for RTT disabled scenario
   */
  buildRttNotEnabledMessage(): MessagePart[] {
    return [
      {
        type: 'text',
        content: `${this.intl.t('ai-agent.playground.fin-answer.language-support-message.rtt-disabled')} `,
      },
      {
        type: 'link',
        content: this.intl.t('ai-agent.playground.fin-answer.language-support-message.enable-it'),
        action: 'goToSettings',
      },
      {
        type: 'text',
        content: this.intl.t(
          'ai-agent.playground.fin-answer.language-support-message.translate-knowledge',
          { language: this.languageName },
        ),
      },
    ];
  }

  /**
   * Builds message parts for RTT enabled scenario
   */
  buildRttEnabledMessage(): MessagePart[] {
    return [
      {
        type: 'text',
        content: `${this.intl.t('ai-agent.playground.fin-answer.language-support-message.rtt-enabled')} `,
      },
      {
        type: 'text',
        content: `${this.intl.t(
          'ai-agent.playground.fin-answer.language-support-message.automatic-translation',
          { language: this.languageName },
        )} `,
      },
      {
        type: 'link',
        content: this.intl.t(
          'ai-agent.playground.fin-answer.language-support-message.re-run-capitalized',
        ),
        action: 'onRerun',
      },
      {
        type: 'text',
        content: this.intl.t(
          'ai-agent.playground.fin-answer.language-support-message.rtt-get-answers',
          { language: this.languageName },
        ),
      },
    ];
  }

  get isOutOfDomainWithRttDisabled(): boolean {
    return Boolean(
      !this.isRttEnabled &&
        ANSWER_STATE_ANSWER_TYPE_MAP[AnswerState.Unanswered].includes(this.args.answerType || ''),
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground::FinAnswer::LanguageSupportMessage': typeof LanguageSupportMessage;
  }
}
