/* import __COLOCATED_TEMPLATE__ from './about-profile-admin-alias-component.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

interface Args {
  editableMode: boolean;
  aliasEnabled: boolean;
  editableAliasName: string;
  aliasSenderEmailAddressSettingsId: string;
}

export default class AboutProfileAdminAliasComponent extends Component<Args> {
  @service intercomEventService: $TSFixMe;

  @tracked showAliasEmailAddressWarning = false;
  @tracked isAdminAliasEmailSideDrawer = false;

  get containerClasses(): string {
    let classes = ['flex', 'mt-2 pt-0.5'];

    if (this.args.editableMode && this.args.aliasEnabled) {
      classes.push('rounded-xl', 'bg-base-module-subtle', 'p-2', '-mx-2');
    }

    return classes.join(' ');
  }

  get flexClasses(): string {
    return this.args.editableMode ? 'flex-col' : 'flex-row';
  }

  get showAliasLabel(): boolean {
    return !this.args.editableAliasName || !this.args.aliasEnabled || this.args.editableMode;
  }

  @action
  openEmailSideDrawer() {
    this.isAdminAliasEmailSideDrawer = true;
    this.showAliasEmailAddressWarning = false;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'open_admin_email_alias_side_drawer',
      context: 'from_alias_email_address_warning',
    });
  }

  @action
  toggleDisplayCustomWarningModal() {
    this.showAliasEmailAddressWarning = !this.showAliasEmailAddressWarning;
    if (this.showAliasEmailAddressWarning) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'create_warning_modal_for_alias_email',
        context: 'from_admin_about_profile',
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Admin::AboutProfileAdminAlias': typeof AboutProfileAdminAliasComponent;
    'admin/about-profile-admin-alias': typeof AboutProfileAdminAliasComponent;
  }
}
