/* import __COLOCATED_TEMPLATE__ from './logs.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import { action } from '@ember/object';
import { scrollToBottom, isScrolledToBottom } from 'embercom/helpers/scrolling-helpers';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { trackedRef } from 'ember-ref-bucket';
import type {
  FinVoiceEvent,
  FinVoiceSession,
} from 'embercom/objects/ai-agent/fin-voice/fin-voice-session';
import { DeduplicatedAsyncData } from 'embercom/resources/utils/async-data';

type FormattedEvent = {
  eventId: string;
  event: string;
  timestamp: string;
  direction: string;
  data: string;
};

export interface LogsArgs {
  sessions: FinVoiceSession[];
  verboseLogsEnabled: boolean;
}

interface Signature {
  Args: LogsArgs;
  Element: HTMLElement;
}

const VERBOSE_EVENTS: FinVoiceEvent['event'][] = [
  'media',
  'unknown_event',
  'fin_transcript_item_delta',
];

export default class Logs extends Component<Signature> {
  @tracked private _userHasScrolled = false;

  @trackedRef('containerElement')
  private _containerElement?: HTMLElement;

  sessionsWatcher = DeduplicatedAsyncData(
    this,
    () => [this.args.sessions],
    (sessions) => {
      if (!this._userHasScrolled && this._containerElement) {
        requestAnimationFrame(() => {
          scrollToBottom(this._containerElement!);
        });
      }
      return sessions;
    },
  );

  get sessions() {
    return (this.sessionsWatcher.value || []).map((session) => {
      let { sessionStartTimestamp, sessionEndTimestamp, events, sessionId } = session;
      let formattedEvents: FormattedEvent[] = [];

      events.forEach((eventData) => {
        let { event, timestamp, direction, ...rest } = eventData;

        if (!this.args.verboseLogsEnabled && VERBOSE_EVENTS.includes(event)) {
          return;
        }

        formattedEvents.push({
          eventId: rest.event_id,
          event,
          timestamp: new Date(timestamp).toLocaleTimeString(),
          direction,
          data: JSON.stringify(rest, null, 2).trim(),
        });
      });

      return {
        sessionId,
        sessionStartTimestamp: new Date(sessionStartTimestamp).toLocaleTimeString(),
        sessionEndTimestamp: sessionEndTimestamp
          ? new Date(sessionEndTimestamp).toLocaleTimeString()
          : null,
        events: formattedEvents,
      };
    });
  }

  @action
  handleScroll() {
    let isAtBottom = isScrolledToBottom(this._containerElement);
    this._userHasScrolled = !isAtBottom;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::VoicePlayground::Logs': typeof Logs;
  }
}
