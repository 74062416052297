/* import __COLOCATED_TEMPLATE__ from './audio-visualizer.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import Component from '@glimmer/component';
import type { WebsocketState } from 'embercom/services/fin-voice-service';
import { trackedRef } from 'ember-ref-bucket';

export interface AudioVisualizerArgs {
  connectionState: WebsocketState;
  isMuted: boolean;
  getFrequencyData: () => { values: Float32Array };
}

interface Signature {
  Args: AudioVisualizerArgs;
  Element: HTMLElement;
}

const BAR_WIDTH = 2;
const BAR_SPACING = 4;
const BAR_HEIGHT_MIN = 2;
const MAX_SIZE = 256; //w-64
const BAR_COLOR = '--accent-border';

export default class AudioVisualizer extends Component<Signature> {
  @trackedRef('canvas', (canvas: HTMLCanvasElement) => canvas)
  private _canvas?: HTMLCanvasElement;

  private _animationFrameId?: number;

  get isConnected() {
    return this.args.connectionState === 'connected';
  }

  get isConnecting() {
    return this.args.connectionState === 'connecting';
  }

  get isDisconnecting() {
    return this.args.connectionState === 'disconnecting';
  }

  constructor(owner: unknown, args: AudioVisualizerArgs) {
    super(owner, args);
    this._renderFrequencyData();
  }

  willDestroy() {
    super.willDestroy();
    if (this._animationFrameId) {
      window.cancelAnimationFrame(this._animationFrameId);
    }
  }

  _renderFrequencyData() {
    if (this._canvas) {
      let clientCanvas = this._canvas;

      // Set the canvas dimensions with device pixel ratio for sharp rendering
      let dpr = window.devicePixelRatio || 1;
      let rect = clientCanvas.getBoundingClientRect();

      clientCanvas.width = rect.width * dpr;
      clientCanvas.height = rect.height * dpr;

      let ctx = clientCanvas.getContext('2d');
      if (ctx) {
        // Scale all drawing operations by dpr for sharp rendering
        ctx.scale(dpr, dpr);

        let result = this.args.getFrequencyData();
        this._draw(result, rect.width, rect.height);
      }
    }
    this._animationFrameId = window.requestAnimationFrame(this._renderFrequencyData.bind(this));
  }

  _normalizeData(frequencies: { values: Float32Array }, totalBars: number, targetBars: number) {
    let normalizedValues = new Float32Array(targetBars);
    let samplesPerBar = totalBars / targetBars;

    // Average the values in this range to reduce the number of bars
    for (let i = 0; i < targetBars; i++) {
      let startIdx = Math.floor(i * samplesPerBar);
      let endIdx = Math.floor((i + 1) * samplesPerBar);
      let sum = 0;

      for (let j = startIdx; j < endIdx; j++) {
        sum += frequencies.values[j];
      }
      normalizedValues[i] = sum / (endIdx - startIdx);
    }

    return normalizedValues;
  }

  _draw(frequencies: { values: Float32Array }, width: number, height: number) {
    if (!this._canvas) {
      return;
    }

    let ctx = this._canvas.getContext('2d');

    if (!ctx) {
      return;
    }

    let context = ctx;
    let centerY = height / 2;

    context.clearRect(0, 0, width, height);

    let totalBars = frequencies.values.length;

    let barAndSpacing = BAR_WIDTH + BAR_SPACING;
    let maxBars = Math.floor(MAX_SIZE / barAndSpacing);
    let availableBars = Math.floor(width / barAndSpacing);
    let targetBars = Math.min(maxBars, availableBars);

    let normalizedValues =
      totalBars > targetBars
        ? this._normalizeData(frequencies, totalBars, targetBars)
        : frequencies.values;

    let totalWidth = targetBars * (BAR_WIDTH + BAR_SPACING) - BAR_SPACING;

    let startX = Math.floor((width - totalWidth) / 2);

    normalizedValues.forEach((value, index) => {
      let x = startX + index * (BAR_WIDTH + BAR_SPACING);

      let barHeight = Math.max(BAR_HEIGHT_MIN, value * (height / 2));

      x = Math.floor(x);
      barHeight = Math.floor(barHeight);

      let fillStyle = getComputedStyle(document.body).getPropertyValue(BAR_COLOR);
      context.fillStyle = fillStyle;

      context.save();
      context.beginPath();

      let radius = BAR_WIDTH / 2;
      let barY = centerY - barHeight / 2;

      context.moveTo(x + radius, barY);
      context.quadraticCurveTo(x + BAR_WIDTH, barY, x + BAR_WIDTH, barY + radius);
      context.lineTo(x + BAR_WIDTH, barY + barHeight - radius);
      context.quadraticCurveTo(
        x + BAR_WIDTH,
        barY + barHeight,
        x + BAR_WIDTH - radius,
        barY + barHeight,
      );
      context.lineTo(x + radius, barY + barHeight);
      context.quadraticCurveTo(x, barY + barHeight, x, barY + barHeight - radius);
      context.lineTo(x, barY + radius);
      context.quadraticCurveTo(x, barY, x + radius, barY);

      context.closePath();
      context.fill();

      context.restore();
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::VoicePlayground::AudioVisualizer': typeof AudioVisualizer;
  }
}
