/* import __COLOCATED_TEMPLATE__ from './guideline-card.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type Guideline from 'embercom/models/ai-agent/guidelines';
import { dropTask } from 'ember-concurrency-decorators';
import { timeout, didCancel, type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { post, put, ajaxDelete } from 'embercom/lib/ajax';
import { action } from '@ember/object';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';
import type { ComposerPublicAPI } from '@intercom/embercom-prosemirror-composer';
import ENV from 'embercom/config/environment';
import type Session from 'embercom/services/session';
import type AiAgentGuidelineService from 'embercom/services/ai-agent-guideline-service';

export interface Args {
  guideline: Guideline;
  index: number;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

enum AssessmentState {
  Inactive,
  Fetching,
  StreamingRewrite,
  FadingInAdviceHeadline,
  StreamingAdvice,
  Settled,
}

export default class GuidelineCard extends Component<Signature> {
  @service declare appService: any;
  @service declare notificationsService: any;
  @service declare store: any;
  @service declare intl: any;
  @service declare intercomConfirmService: IntercomConfirmService;
  @service declare session: Session;
  @service declare aiAgentGuidelineService: AiAgentGuidelineService;
  @service declare intercomEventService: $TSFixMe;

  @tracked isOpen = true;
  @tracked isViewingImprovedGuideline = false;
  @tracked assessmentStatusTextForDisplay = '';
  @tracked assessmentAdviceForDisplay = '';
  @tracked guidelineModifiedSinceLastAssessment = false;
  @tracked composerApi: ComposerPublicAPI | null = null;
  @tracked assessmentState: AssessmentState = AssessmentState.Inactive;

  guideline = this.args.guideline;
  openSectionId = 'advice';
  AssessmentStates = AssessmentState;

  get app(): any {
    return this.appService.app;
  }

  get hasUnsavedChanges(): boolean {
    return Boolean(this.guideline.hasDirtyAttributes);
  }

  get validationErrors(): string[] {
    return this.guideline.validations.isInvalid ? this.guideline.validations.messages : [];
  }

  get isInvalid(): boolean {
    return this.guideline.validations.isInvalid;
  }

  get isValid(): boolean {
    return !this.isInvalid;
  }

  get assessmentIsIdle(): boolean {
    return (
      this.assessmentState === AssessmentState.Inactive ||
      this.assessmentState === AssessmentState.Settled
    );
  }

  get isEnableButtonDisabled(): boolean {
    return !this.assessmentIsIdle || this.isInvalid;
  }

  get isCancelButtonDisabled(): boolean {
    return !this.assessmentIsIdle || !this.hasUnsavedChanges;
  }

  get isSaveButtonDisabled(): boolean {
    return !this.assessmentIsIdle || this.isInvalid || !this.hasUnsavedChanges;
  }

  get isOptimizeButtonDisabled(): boolean {
    return (
      (this.guideline.assessment !== null && !this.guidelineModifiedSinceLastAssessment) ||
      this.isInvalid ||
      (this.guideline.createdFromTemplate && !this.guideline.promptModified)
    );
  }

  get assessmentAdviceHeadline(): string {
    if (
      this.guideline.assessment?.hasIssue &&
      !this.guideline.assessment?.shouldRewrite &&
      this.guideline.assessment?.adviceHeadline
    ) {
      return this.guideline.assessment.adviceHeadline;
    }
    return this.intl.t('ai-agent.guidance.guideline-assessment.advice-headline');
  }

  get shouldDisplayAssessmentAdvice(): boolean {
    return (
      this.guideline.assessment !== null &&
      this.assessmentState >= AssessmentState.FadingInAdviceHeadline &&
      !(this.guideline.assessment.shouldRewrite && !this.isViewingImprovedGuideline)
    );
  }

  get optimizeButtonDisabledTooltip(): string {
    if (this.isInvalid) {
      return this.intl.t('ai-agent.guidance.guideline-assessment.disabled-tooltip.empty-guideline');
    }
    if (!this.guidelineModifiedSinceLastAssessment) {
      return this.intl.t(
        'ai-agent.guidance.guideline-assessment.disabled-tooltip.no-changes-since-last-assessment',
      );
    }
    return '';
  }

  get shouldShowExamplesEmptyState(): boolean {
    if (this.args.guideline.category === 'other') {
      return false;
    }

    // Show examples for new guidelines that haven't been modified
    if (
      !this.guideline.hasState &&
      !this.guideline.promptModified &&
      !this.guideline.createdFromTemplate
    ) {
      return true;
    }

    return false;
  }

  @action
  registerRefreshHook(hook: () => void) {
    this.aiAgentGuidelineService.registerRefreshHook(hook);
  }

  @action
  unregisterRefreshHook(hook: () => void) {
    this.aiAgentGuidelineService.unregisterRefreshHook(hook);
  }

  @action
  cancel(): void {
    this.guideline.rollbackAttributes();
    this.aiAgentGuidelineService.updatePreview();
    this.resetAssessmentState();
  }

  @action
  viewOriginalGuideline() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'view_original_button',
      place: 'guidance_card',
      section: 'guidance',
    });
    this.guideline.set('prompt', this.guideline.assessment?.originalPrompt);
    this.aiAgentGuidelineService.updatePreview();
    this.isViewingImprovedGuideline = false;
  }

  @action
  viewImprovedGuideline() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'view_suggestion_button',
      place: 'guidance_card',
      section: 'guidance',
    });
    this.guideline.set('prompt', this.guideline.assessment?.suggestedRewrite);
    this.aiAgentGuidelineService.updatePreview();
    this.isViewingImprovedGuideline = true;
  }

  @action
  onReady(composer: ComposerPublicAPI) {
    this.composerApi = composer;
    setTimeout(() => {
      this.composerApi?.composer.commands.focus();
    }, 100);
  }

  @action
  cancelAnimations() {
    taskFor(this.updateImprovingText).cancelAll();
    taskFor(this.streamGuidelineRewrite).cancelAll();
    taskFor(this.fadeInAdviceHeadline).cancelAll();
    taskFor(this.streamAdvice).cancelAll();
  }

  @action
  cancelImprovement() {
    taskFor(this.runGuidelineAssessment).cancelAll();
    this.cancelAnimations();
    this.resetAssessmentState();
  }

  @action
  resetAssessmentState() {
    this.assessmentState = AssessmentState.Inactive;
    this.assessmentStatusTextForDisplay = '';
    this.assessmentAdviceForDisplay = '';
    this.isViewingImprovedGuideline = false;
    this.guidelineModifiedSinceLastAssessment = false;
    this.guideline.discardAssessment();
  }

  @action
  handleGuidelinePromptChange() {
    // discard the assessment if the user modified the guideline after being
    // presented with rewrite advice
    if (
      !this.isViewingImprovedGuideline &&
      this.guideline.assessment?.shouldRewrite &&
      this.guideline.assessment?.suggestedRewrite
    ) {
      this.resetAssessmentState();
    } else {
      this.guidelineModifiedSinceLastAssessment = true;
    }
    this.guideline.promptModified = true;
    this.aiAgentGuidelineService.handleUserIsTyping();
  }

  @action kickOffOptimization() {
    try {
      taskFor(this.runGuidelineAssessment).perform();
    } catch (e) {
      // only throw errors that are not related to task cancellation
      if (!didCancel(e)) {
        throw e;
      }
    }
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'optimize_button',
      place: 'guidance_card',
      section: 'guidance',
    });
  }

  @dropTask
  *save(): TaskGenerator<void> {
    try {
      yield this._saveChangedGuideline();
      this.notificationsService.notifyConfirmation(
        this.intl.t('ai-agent.guidance.save-success-confirmation'),
      );
    } catch (e) {
      this.notificationsService.notifyResponseError(e);
    }
  }

  @dropTask
  *saveAndEnableGuideline(): TaskGenerator<void> {
    let record = this.guideline;
    try {
      yield this._saveChangedGuideline();
      let response = yield put(`/ember/ai_agent/guidelines/${record.id}/enable`, {
        id: record.id,
        app_id: this.appService.app.id,
      });
      if (response) {
        record.reload();
      }
      this.notificationsService.notifyConfirmation(
        this.intl.t('ai-agent.guidance.save-success-confirmation'),
      );
    } catch (e) {
      this.notificationsService.notifyResponseError(e);
    }
  }

  @dropTask
  *disableGuideline(): TaskGenerator<void> {
    let record = this.guideline;
    try {
      let response = yield put(`/ember/ai_agent/guidelines/${record.id}/disable`, {
        id: record.id,
        app_id: this.appService.app.id,
      });
      if (response) {
        record.reload();
      }
      this.notificationsService.notifyConfirmation(
        this.intl.t('ai-agent.guidance.save-success-confirmation'),
      );
    } catch (e) {
      this.notificationsService.notifyResponseError(e);
    }
  }

  @dropTask
  *deleteGuideline(): TaskGenerator<void> {
    let record = this.guideline;
    let confirmed = yield this.intercomConfirmService.confirm({
      title: this.intl.t('ai-agent.guidance.guidelines.delete-confirmation.title'),
      body: this.intl.t('ai-agent.guidance.guidelines.delete-confirmation.body'),
      confirmButtonText: this.intl.t(
        'ai-agent.guidance.guidelines.delete-confirmation.confirm-button-text',
      ),
      primaryButtonType: 'primary-destructive',
    });
    if (!confirmed) {
      return;
    }
    try {
      yield ajaxDelete(`/ember/ai_agent/guidelines/${record.id}`, {
        id: record.id,
        app_id: this.appService.app.id,
      });
      yield this.store.unloadRecord(record);
      yield this.aiAgentGuidelineService.sendPreviewSettingsUpdatedMessage();
      this.notificationsService.notifyConfirmation(
        this.intl.t('ai-agent.guidance.delete-success-confirmation'),
      );
    } catch (e) {
      this.notificationsService.notifyResponseError(e);
    }
  }

  @dropTask
  *runGuidelineAssessment(): TaskGenerator<void> {
    this.resetAssessmentState();
    this.assessmentState = AssessmentState.Fetching;
    this.composerApi?.composer.disableInteractivity();
    taskFor(this.updateImprovingText).perform();

    type GuidelineCheckPayload = {
      app_id: any;
      category: string;
      detected_locale: string;
      prompt: string;
      guideline_id?: string;
    };

    let payload: GuidelineCheckPayload = {
      app_id: this.appService.app.id,
      category: this.guideline.category,
      detected_locale: this.session.locale.split('-')[0],
      prompt: this.guideline.prompt,
    };

    if (!this.guideline.isNew) {
      payload = {
        ...payload,
        guideline_id: this.guideline.id,
      };
    }

    try {
      let response = yield post(`/ember/ai_agent/guidelines/get_single_guidance_check`, payload);

      if (response) {
        this.guideline.setAssessment(response);
        if (
          this.guideline.assessment?.shouldRewrite &&
          this.guideline.assessment?.suggestedRewrite
        ) {
          yield taskFor(this.streamGuidelineRewrite).perform();
        }
        yield taskFor(this.fadeInAdviceHeadline).perform();
        yield taskFor(this.streamAdvice).perform();
        this.assessmentState = AssessmentState.Settled;
      } else {
        throw new Error('No response from guideline assessment');
      }
    } catch (e) {
      this.notificationsService.notifyResponseError(e);
      this.resetAssessmentState();
      this.cancelAnimations();
    }
    this.composerApi?.composer.enableInteractivity();
  }

  @dropTask
  *updateImprovingText(): TaskGenerator<void> {
    let iterationDelayMs = ENV.APP._400MS;
    let statusMessages: string[] = [
      this.intl.t('ai-agent.guidance.guideline-assessment-status-messages.thinking'),
      this.intl.t('ai-agent.guidance.guideline-assessment-status-messages.assessing-feasibility'),
      this.intl.t('ai-agent.guidance.guideline-assessment-status-messages.quick-safety-check'),
      this.intl.t('ai-agent.guidance.guideline-assessment-status-messages.testing-overlaps'),
      this.intl.t('ai-agent.guidance.guideline-assessment-status-messages.catching-contradictions'),
      this.intl.t('ai-agent.guidance.guideline-assessment-status-messages.working-the-words'),
      this.intl.t('ai-agent.guidance.guideline-assessment-status-messages.wrapping-up'),
    ];
    let lastMessage = statusMessages.pop()!;
    for (let statusMessage of statusMessages) {
      this.assessmentStatusTextForDisplay = statusMessage;
      yield timeout(iterationDelayMs);
      for (let i = 0; i < 3; i++) {
        this.assessmentStatusTextForDisplay += '.';
        yield timeout(iterationDelayMs);
      }
    }
    // Keep showing the last message until the assessment finished fetching
    while (this.assessmentState === AssessmentState.Fetching) {
      this.assessmentStatusTextForDisplay = lastMessage;
      yield timeout(iterationDelayMs);
      for (let i = 0; i < 3; i++) {
        this.assessmentStatusTextForDisplay += '.';
        yield timeout(iterationDelayMs);
      }
    }
  }

  @dropTask
  *streamGuidelineRewrite(): TaskGenerator<void> {
    this.assessmentState = AssessmentState.StreamingRewrite;
    let separator = ' ';
    let suggestedRewriteParts = this.guideline.assessment?.suggestedRewrite?.split(separator);
    let targetStreamDurationMs = ENV.APP._2000MS;
    let partialText = '';
    if (suggestedRewriteParts) {
      for (let i = 0; i < suggestedRewriteParts.length; i++) {
        partialText += (i > 0 ? separator : '') + suggestedRewriteParts[i];
        this.guideline.set('prompt', partialText);
        this.aiAgentGuidelineService.callComposerRefreshHooks();
        yield timeout(targetStreamDurationMs / suggestedRewriteParts.length);
      }
    }
    // explicitly call into viewImprovedGuideline to make sure there's no unexpected
    // consequence of splitting and rejoining the guidline to facilitate the animation
    this.viewImprovedGuideline();
  }

  @dropTask
  *fadeInAdviceHeadline(): TaskGenerator<void> {
    this.assessmentState = AssessmentState.FadingInAdviceHeadline;
    // the fade itself is handled by a tailwind animation utility class
    // so we just delay by the animation duration before streaming the advice
    yield timeout(ENV.APP._500MS);
  }

  @dropTask
  *streamAdvice(): TaskGenerator<void> {
    if (!this.guideline.assessment?.advice) {
      return;
    }
    this.assessmentState = AssessmentState.StreamingAdvice;
    let separator = ' ';
    let adviceParts = this.guideline.assessment.advice.split(separator);
    let targetStreamDurationMs = ENV.APP._2000MS;
    let partialText = '';
    if (adviceParts) {
      for (let i = 0; i < adviceParts.length; i++) {
        partialText += (i > 0 ? separator : '') + adviceParts[i];
        this.assessmentAdviceForDisplay = partialText;
        yield timeout(targetStreamDurationMs / adviceParts.length);
      }
    }
    this.assessmentAdviceForDisplay = this.guideline.assessment.advice;
  }

  async _saveChangedGuideline(): Promise<void> {
    let record = this.guideline;

    if (!record.hasState) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'save_button',
        place: 'guideline_card',
        section: 'guidance',
        used_template: record.createdFromTemplate,
      });
    }
    if (!record.hasDirtyAttributes) {
      return;
    }
    await record.save();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Guidance::GuidelineCard': typeof GuidelineCard;
  }
}
