/* import __COLOCATED_TEMPLATE__ from './question-bulk-select-actions.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent-2 */

import templateOnly from '@ember/component/template-only';

interface QuestionBulkSelectActionsSignature {
  Args: {
    isPlaygroundActive: boolean;
    areQuestionsBulkSelected: boolean;
    countOfFilteredQuestions: number;
    countOfBulkSelectedQuestions: number;
    areAnyFiltersApplied: boolean;
    updateShowConfirmTestRunModal: (showConfirmTestRunModal: boolean) => void;
    updateShowConfirmDeleteQuestionsModal: (showConfirmDeleteQuestionsModal: boolean) => void;
  };
}

const QuestionBulkSelectActions = templateOnly<QuestionBulkSelectActionsSignature>();

export default QuestionBulkSelectActions;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground::QuestionBulkSelectActions': typeof QuestionBulkSelectActions;
  }
}
