/* import __COLOCATED_TEMPLATE__ from './collapsible-list.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import type Guideline from 'embercom/models/ai-agent/guidelines';
import type Chart from 'embercom/models/reporting/custom/chart';
// @ts-ignore no type declaration available for ember-copy
import type ReportingUnderlyingDataService from 'embercom/services/reporting-underlying-data-service';
import type AiAgentGuidelineService from 'embercom/services/ai-agent-guideline-service';
import type IntlService from 'ember-intl/services/intl';
import type Store from '@ember-data/store';
import type { GuidelineCategory } from 'embercom/models/ai-agent/guidelines';
import type AttributeInfoResolver from 'embercom/lib/common/template-attribute-resolver';

export enum Stat {
  USED = 'used',
  RESOLVED = 'resolved',
  ROUTED = 'routed',
}

export interface Args {
  title: string;
  category: GuidelineCategory;
  description: string;
  guidelines: Guideline[];
  icon: InterfaceIconName;
  maxGuidelineCount: number;
  chartDefinitionsHash: Record<string, Record<Stat, any>>;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

export default class GuidelinesCollapsibleList extends Component<Signature> {
  @service declare appService: any;
  @service declare notificationsService: any;
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare permissionsService: $TSFixMe;
  @service declare aiAgentGuidelineService: AiAgentGuidelineService;
  @service declare intercomEventService: $TSFixMe;

  @tracked isOpen = true;
  @tracked selectedGuideline?: Guideline;
  @tracked sideDrawerOpen = false;
  @tracked chart?: Chart;
  @service declare reportingUnderlyingDataService: ReportingUnderlyingDataService;
  @tracked selectedStat?: Stat;

  stats = [Stat.USED, Stat.RESOLVED, Stat.ROUTED];

  drillInColumns = [
    'conversation.id',
    'conversation.first_user_conversation_part_created_at',
    'conversation.fin.resolution_state',
    'conversation.channel_type',
    'conversation.bot_conversation_rating.rating_index',
    'conversation.fin.guidelines_applied.guideline_ids',
  ];

  get app(): any {
    return this.appService.app;
  }

  get attributeInfoResolver(): AttributeInfoResolver {
    return this.aiAgentGuidelineService.attributeInfoResolver();
  }

  get arrowIconClass(): InterfaceIconName {
    return this.isOpen ? 'small-thin-down-arrow' : 'small-thin-right-arrow';
  }

  get maybeEmptyStateClasses(): string {
    return this.args.guidelines.length ? '' : 'filter grayscale opacity-50';
  }

  get headerOpenClassName(): string {
    return 'collapsible-list__header__open net-new-flag';
  }

  get openSectionId(): string | null {
    return this.aiAgentGuidelineService.openGuidelineId;
  }

  get guidelinesForCategory(): Guideline[] {
    return this.args.guidelines
      .filter((guideline) => guideline.category === this.args.category && !guideline.isDeleted)
      .sort((a, b) => {
        // If a is temp (unsaved guideline), it comes last
        if (String(a.id).startsWith('temp-')) {
          return 1;
        }
        if (String(b.id).startsWith('temp-')) {
          return -1;
        }

        // Otherwise sort by ID in ascending order (highest number last)
        return Number(a.id) - Number(b.id);
      });
  }

  get guidelineCount(): number {
    return this.args.guidelines.filter((guideline) => !guideline.isDeleted).length;
  }

  get cannotAddNewGuidelines(): boolean {
    return this.guidelineCount >= this.args.maxGuidelineCount || this.hasUnsavedGuidelineInCategory;
  }

  get hasUnsavedGuidelineInCategory(): boolean {
    return this.args.guidelines.some(
      (guideline) => guideline.category === this.args.category && !guideline.hasState,
    );
  }

  get shouldShowEmptyState(): boolean {
    return this.guidelinesForCategory.length === 0 && this.args.category !== 'other';
  }

  get canAdminAccessReporting(): boolean {
    return this.permissionsService.currentAdminCan('can_access_reporting');
  }

  @action
  cancelGuideline(guideline: Guideline) {
    guideline.rollbackAttributes();
    this.aiAgentGuidelineService.updatePreview();
  }

  @action
  toggleOpenState(): void {
    this.isOpen = !this.isOpen;
  }

  @action
  onOpenSectionChange(sectionId: string) {
    this.aiAgentGuidelineService.setOpenGuidelineId(sectionId);
  }

  @action
  createNewGuideline(): void {
    let newGuideline = this.aiAgentGuidelineService.createNewGuideline(this.args.category, null);

    this.openAccordionSection(newGuideline);
  }

  @action
  openAccordionSection(guideline: Guideline) {
    this.isOpen = true;
    this.aiAgentGuidelineService.setOpenGuidelineId(guideline.id);
  }

  @action
  openSideDrawer(guideline: Guideline, stat: Stat, event: Event) {
    this.trackEvent(stat);
    event.stopPropagation();
    event.preventDefault();
    if (this.permissionsService.currentAdminCan('can_reporting__drillin__access')) {
      this.selectedGuideline = guideline;
      this.selectedStat = stat;
    } else {
      this.permissionsService.loadAllAdminsAndShowPermissionRequestModal(
        'can_reporting__drillin__access',
      );
    }
  }

  @action
  trackEvent(stat_clicked: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'guidance_stat',
      place: 'guidance_card',
      section: 'guidance',
      stat: stat_clicked,
    });
  }

  @action
  closeSideDrawer() {
    this.selectedGuideline = undefined;
    this.selectedStat = undefined;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Guidance::CollapsibleList': typeof GuidelinesCollapsibleList;
  }
}
