/* import __COLOCATED_TEMPLATE__ from './inspect-answer-section.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent-2 */

import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    openSectionId: string;
    onOpenSectionChange: (sectionId: string) => void;
    sectionId: string;
    headerText: string;
    stampText: string;
    isDisabled?: boolean;
  };
  Element: HTMLDivElement;
  Blocks: {
    default: [];
  };
}

const InspectAnswerSectionComponent = templateOnlyComponent<Signature>();
export default InspectAnswerSectionComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground::FinAnswer::InspectAnswerSection': typeof InspectAnswerSectionComponent;
    'ai-agent/playground/fin-answer/inspect-answer-section': typeof InspectAnswerSectionComponent;
  }
}
