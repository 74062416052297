/* import __COLOCATED_TEMPLATE__ from './test-settings-modal.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent-2 */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import type { PlaygroundSettings } from 'embercom/lib/fin-playground/settings';
import type UserSummary from 'embercom/objects/inbox/user-summary';
import { tracked } from '@glimmer/tracking';
import type InboxSearchSuggestionsService from 'embercom/services/inbox-search-suggestions-service';
import { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency-decorators';
import type { TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';

interface TestSettingsModalArgs {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  playgroundSettings: PlaygroundSettings;
  withBrandDropdown?: boolean;
  withUserDropdown?: boolean;
}

export default class TestSettingsModal extends Component<TestSettingsModalArgs> {
  @tracked user?: UserSummary = undefined;
  @tracked storedUser?: UserSummary = undefined;
  @service declare inboxSearchSuggestionsService: InboxSearchSuggestionsService;

  @tracked audienceOrUser: 'AUDIENCES' | 'USER' = this.user ? 'USER' : 'AUDIENCES';

  constructor(owner: any, args: TestSettingsModalArgs) {
    super(owner, args);
    if (args.playgroundSettings.userId) {
      taskFor(this.loadStoredUser).perform(args.playgroundSettings.userId);
    }
  }

  get userTargetingEnabled() {
    return this.args.withUserDropdown && this.audienceOrUser === 'USER';
  }

  @dropTask
  *loadStoredUser(userId: string): TaskGenerator<void> {
    this.user = yield this.inboxSearchSuggestionsService.loadUserFromId(userId);
    this.storedUser = this.user;
  }

  @action
  save() {
    this.args.onSave();
    this.storedUser = this.user;
    this.args.onClose();
  }

  @action
  onCancel() {
    this.args.playgroundSettings.rollbackChanges();
    this.user = this.storedUser;
    this.args.onClose();
  }

  @action
  onSelectUser(user: UserSummary | null) {
    if (user) {
      this.args.playgroundSettings.userId = user.id;
      this.user = user;
    } else {
      // we use null here so that we explicitly define that there is no user
      // if we used undefined, the update request would not even pass a userId
      this.args.playgroundSettings.userId = null;
      this.user = undefined;
    }
  }

  @action
  onAudienceOrUserSelectionChange(value: 'AUDIENCES' | 'USER') {
    if (value === 'AUDIENCES') {
      // If we were in the user tab, roll back to the previous segments
      this.args.playgroundSettings.rollbackChanges();
      // If changing audiences, we want to reset the user
      this.onSelectUser(null);
    } else {
      // If changing user, we want to reset the segments
      this.args.playgroundSettings.removeAllSegments();
      // If we were in the audiences tab, we want to go back to the stored user
      // We don't use rollbackChanges() here because we have to keep the user variable up to date
      this.onSelectUser(this.storedUser || null);
    }

    this.audienceOrUser = value;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground::Modals::TestSettingsModal': typeof TestSettingsModal;
  }
}
