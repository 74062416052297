/* import __COLOCATED_TEMPLATE__ from './empty-state.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Guideline from 'embercom/models/ai-agent/guidelines';
import { action } from '@ember/object';
import type AiAgentGuidelineService from 'embercom/services/ai-agent-guideline-service';
import type { GuidelineExample } from 'embercom/services/ai-agent-guideline-service';
import type { GuidelineCategory } from 'embercom/models/ai-agent/guidelines';

export interface Args {
  category: GuidelineCategory;
  guideline?: Guideline;
  openAccordionSection?: (guideline: Guideline) => void;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

export default class EmptyState extends Component<Signature> {
  @service declare aiAgentGuidelineService: AiAgentGuidelineService;
  @service declare intercomEventService: $TSFixMe;
  guideline: Guideline | undefined;

  constructor(owner: any, args: Args) {
    super(owner, args);
    this.guideline = this.args.guideline;
  }

  get exampleGuidelines(): GuidelineExample[] {
    return this.args.category === 'other'
      ? []
      : this.aiAgentGuidelineService.getEmptyStateGuidelinesForCategory(this.args.category);
  }

  get attributeInfoResolver() {
    return this.aiAgentGuidelineService.attributeInfoResolver();
  }

  @action
  getSerializedBlocks(prompt: string) {
    return this.aiAgentGuidelineService.serializedBlocksFromPrompt(prompt);
  }

  @action
  addExampleGuideline(example: GuidelineExample) {
    // add from guideline-card empty-state (with guideline)
    if (this.args.guideline) {
      this.aiAgentGuidelineService.handleAddFromExampleGuidelineEmptyState(
        this.args.guideline,
        example,
      );
    } else if (this.args.openAccordionSection) {
      // add from collapsible-list empty state (no guideline)
      this.guideline = this.aiAgentGuidelineService.handleAddFromExampleCategoryEmptyState(
        this.args.category,
        example,
      );

      this.args.openAccordionSection(this.guideline);
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'template_pill',
      place: 'templates_area',
      section: 'guidance',
      template_title: example.title,
      template_category: this.args.category,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Guidance::EmptyState': typeof EmptyState;
  }
}
