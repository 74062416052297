/* import __COLOCATED_TEMPLATE__ from './missing-targets-modal.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent-2 */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    resetSettingsAndRunQuestions: () => void;
    downloadReport?: () => void;
    withDownloadReport?: boolean;
  };
}

const MissingTargetsModal = templateOnlyComponent<Signature>();
export default MissingTargetsModal;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground::Modals::MissingTargetsModal': typeof MissingTargetsModal;
  }
}
