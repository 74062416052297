/* import __COLOCATED_TEMPLATE__ from './text-message-input.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type IntlService from 'ember-intl/services/intl';
import type { WebsocketState } from 'embercom/services/fin-voice-service';

export interface TextMessageInputArgs {
  connectionState: WebsocketState;
  sendTextMessage: (message: string) => void;
}

interface Signature {
  Args: TextMessageInputArgs;
  Element: HTMLElement;
}

export default class TextMessageInput extends Component<Signature> {
  @service declare intl: IntlService;

  @tracked textMessage = '';

  get isConnected() {
    return this.args.connectionState === 'connected';
  }

  get hasTextMessage() {
    return !!this.textMessage?.trim();
  }

  @action
  updateTextMessage(event: Event) {
    this.textMessage = (event.target as HTMLInputElement).value;
  }

  @action
  keyDown(event: KeyboardEvent) {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      this.sendTextMessage();
    }
  }

  @action
  sendTextMessage() {
    if (!this.isConnected || !this.hasTextMessage) {
      return;
    }

    this.args.sendTextMessage(this.textMessage);
    this.textMessage = '';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::VoicePlayground::TextMessageInput': typeof TextMessageInput;
  }
}
