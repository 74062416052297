/* import __COLOCATED_TEMPLATE__ from './update-settings-modal.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent-2 */
import Component from '@glimmer/component';

interface Signature {
  Args: {
    onModalClose: () => void;
    updateSettingsAndRunQuestions: () => void;
    downloadReport: () => void;
    useGenericCopy?: boolean;
  };
}

export default class UpdateSettingsModal extends Component<Signature> {
  get modalCopyKey() {
    return this.args.useGenericCopy
      ? 'ai-agent.playground.settings.rerun-test-generic-modal.'
      : 'ai-agent.playground.settings.rerun-test-modal.';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground::Modals::UpdateSettingsModal': typeof UpdateSettingsModal;
  }
}
