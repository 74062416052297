/* import __COLOCATED_TEMPLATE__ from './brand-dropdown.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent-2 */

import Component from '@glimmer/component';

import { inject as service } from '@ember/service';
import type AiContentSegmentsService from 'embercom/services/ai-content-segments-service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import type RouterService from '@ember/routing/router-service';
import type { PlaygroundSettings } from 'embercom/lib/fin-playground/settings';
import type BrandService from 'embercom/services/brand-service';
import type Brand from 'embercom/models/brand';

export interface Args {
  playgroundSettings: PlaygroundSettings;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

export default class BrandDropdown extends Component<Signature> {
  @service declare appService: any;
  @service declare aiContentSegmentsService: AiContentSegmentsService;
  @service declare intl: any;
  @service declare router: RouterService;
  @service declare notificationsService: any;
  @tracked isDropdownOpen = false;
  @tracked showModal = false;
  @tracked isRunning = false;

  @service declare brandService: BrandService;

  private DEFAULT_BRAND_ID = '0';

  constructor(owner: unknown, args: Args) {
    super(owner, args);
  }

  get brands() {
    return this.brandService.brands;
  }

  get dropdownLabel() {
    if (
      this.args.playgroundSettings?.brandId &&
      this.args.playgroundSettings?.brandId !== this.DEFAULT_BRAND_ID
    ) {
      return this.brands.find((brand) => brand.id === this.args.playgroundSettings?.brandId)?.name;
    }

    return this.appService.app.name;
  }

  get selectedBrandId() {
    let foundBrand = this.brands.find(
      (brand) => brand.id === this.args.playgroundSettings?.brandId,
    );
    if (foundBrand) {
      return foundBrand.id;
    }
    return this.DEFAULT_BRAND_ID;
  }

  get brandsList() {
    let defaultBrandOption = {
      text: this.appService.app.name,
      value: this.DEFAULT_BRAND_ID,
    };

    // we filter out the default here because the store behaviour is unreliable
    // if there is only the default brand, it is present in the store findAll
    // but if there are more, due to ID type mismatch (default ID is string), the default brand is not present
    // so we handle it ourselves
    let customBrandOptions = this.brands
      .filter((brand) => !brand.isDefault)
      .map((brand: Brand) => ({
        text: brand.name,
        value: brand.id,
      }));

    return [defaultBrandOption, ...customBrandOptions].sort((a, b) => a.text.localeCompare(b.text));
  }

  @action onSelectBrand(brandId: string) {
    if (brandId === this.DEFAULT_BRAND_ID) {
      this.args.playgroundSettings.brandId = null;
    } else {
      this.args.playgroundSettings.brandId = brandId;
    }
  }

  @action redirectToBrandSettings() {
    let url = this.router.urlFor('apps.app.settings.workspace.brands', this.appService.app.id);
    safeWindowOpen(url, '_blank');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground::Settings::BrandDropdown': typeof BrandDropdown;
    'ai-agent/playground/settings/brand-dropdown': typeof BrandDropdown;
  }
}
