/* import __COLOCATED_TEMPLATE__ from './manage-actions-cta.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import { tracked } from '@glimmer/tracking';

import Component from '@glimmer/component';
import type SetupAction from 'embercom/models/workflow-connector/setup-action';
import { inject as service } from '@ember/service';
import type ArrayProxy from '@ember/array/proxy';

export default class ManageActionsCta extends Component {
  @tracked actions: SetupAction[] = [];
  @service declare store: $TSFixMe;

  constructor(owner: any, args: any) {
    super(owner, args);
    this.fetchActions();
  }

  async fetchActions() {
    let actions: ArrayProxy<SetupAction> = await this.store.findAll(
      'workflow-connector/setup-action',
    );

    if (!actions) {
      return;
    }
    this.actions = actions
      .filterBy('isTemplate', false)
      .filter((act) => act.finEnabled || act.usage === 'fin')
      .slice(0, 3);
  }

  get customActionsRoute() {
    return 'apps.app.settings.app-settings.custom-actions.custom-action';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAutomation::ManageActionsCta': typeof ManageActionsCta;
    'ai-automation/manage-actions-cta': typeof ManageActionsCta;
  }
}
