/* import __COLOCATED_TEMPLATE__ from './conversation-item.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import Component from '@glimmer/component';
import type {
  FinVoiceConversationEntryFunctionCall,
  FinVoiceConversationEntryMessage,
  FinVoiceConversationEntryStart,
} from 'embercom/objects/ai-agent/fin-voice/fin-voice-session';

export interface ConversationArgs {
  conversationItem: FormattedConversationItem;
  previousItem?: FormattedConversationItem;
  nextItem?: FormattedConversationItem;
  verboseLogsEnabled: boolean;
}

interface Signature {
  Args: ConversationArgs;
  Element: HTMLElement;
}

export type FormattedConversationItem =
  | FormattedConversationMessage
  | FormattedConversationFunctionCall
  | FormattedConversationStart;

export type FormattedConversationMessage = Pick<
  FinVoiceConversationEntryMessage,
  'status' | 'itemId' | 'type' | 'transcript' | 'errorCode'
> & {
  participant: 'user' | 'fin';
  timestamp: string;
};

export type FormattedConversationFunctionCall = Pick<
  FinVoiceConversationEntryFunctionCall,
  'status' | 'itemId' | 'type' | 'toolName' | 'toolCallId' | 'toolOutput' | 'toolArguments'
> & {
  timestamp: string;
};

export type FormattedConversationStart = Pick<
  FinVoiceConversationEntryStart,
  'status' | 'itemId' | 'type' | 'streamId' | 'voice' | 'pipeline'
> & {
  timestamp: string;
  vadSettings: string;
};

export default class ConversationItem extends Component<Signature> {
  get containerStyling() {
    let styling: string[] = [];
    let { previousItem, nextItem, conversationItem } = this.args;

    let margin = 'mt-2';

    // Default margin is 6. Add a smaller margin if the types match
    if (previousItem?.type !== conversationItem.type) {
      margin = 'mt-5';
    }

    if (
      conversationItem.type === 'message' &&
      previousItem?.type === 'message' &&
      previousItem.participant !== conversationItem.participant
    ) {
      margin = 'mt-5';
    }

    styling.push(margin);

    if (conversationItem.type === 'message') {
      styling.push('flex flex-col gap-2 w-fit max-w-128 px-4 py-3 rounded-3xl text-sm');

      if (conversationItem.participant === 'fin') {
        styling.push('bg-neutral-container text-default mr-auto');
      } else {
        styling.push('bg-neutral-fill text-on-neutral text-right ml-auto');
      }

      // If current item is a user message, and next item is a user message, then the bottom right border should not be rounded.
      // If current item is a user message, and previous item is a user message, then the top right border should not be rounded.
      // If current item is a fin message, and next item is a fin message, then the bottom left border should not be rounded.
      // If current item is a fin message, and previous item is a fin message, then the top left border should not be rounded.
      if (conversationItem.participant === 'user') {
        if (
          !!previousItem &&
          previousItem.type === 'message' &&
          previousItem.participant === 'user'
        ) {
          styling.push('rounded-tr-none');
        }
        if (!!nextItem && nextItem.type === 'message' && nextItem.participant === 'user') {
          styling.push('rounded-br-none');
        }
      } else {
        if (
          !!previousItem &&
          (previousItem.type !== 'message' || previousItem?.participant !== 'user')
        ) {
          styling.push('rounded-tl-none');
        }
        if (!!nextItem && (nextItem.type !== 'message' || nextItem.participant !== 'user')) {
          styling.push('rounded-bl-none');
        }
      }
    }

    return styling.join(' ');
  }

  get transcriptStyling() {
    if (this.args.conversationItem.type !== 'message') {
      return '';
    }

    let styling: string[] = [];

    if (
      this.args.conversationItem.status === 'loading' ||
      this.args.conversationItem.status === 'error'
    ) {
      styling.push('italic');

      if (this.args.conversationItem.participant === 'fin') {
        styling.push('text-muted');
      }
    }

    return styling.join(' ');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::VoicePlayground::ConversationItem': typeof ConversationItem;
  }
}
