/* import __COLOCATED_TEMPLATE__ from './audience-dropdown.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent-2 */

import Component from '@glimmer/component';

import { inject as service } from '@ember/service';
import type AiContentSegmentsService from 'embercom/services/ai-content-segments-service';
import { MAX_NB_CHARS_FOR_AUDIENCES_NAME } from 'embercom/services/ai-content-segments-service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import type RouterService from '@ember/routing/router-service';
import type { PlaygroundSettings } from 'embercom/lib/fin-playground/settings';

export interface Args {
  handleDropDownClosed?: () => void;
  playgroundSettings: PlaygroundSettings;
  asFilter?: boolean;
  showsIconOnly?: boolean;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

export default class AudienceDropdown extends Component<Signature> {
  @service declare appService: any;
  @service declare aiContentSegmentsService: AiContentSegmentsService;
  @service declare intl: any;
  @service declare router: RouterService;
  @service declare notificationsService: any;
  @tracked isDropdownOpen = false;
  @tracked showModal = false;
  @tracked isRunning = false;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
  }

  get segments() {
    return this.aiContentSegmentsService.segments;
  }

  get dropdownLabel() {
    return this.selectedSegmentIds?.length
      ? this.firstSelectedSegmentName
      : this.intl.t('knowledge-hub.content-editor.shared.audience-section.dropdown.everyone');
  }

  get firstSelectedSegmentName(): string | undefined {
    let firstSegmentName = this.segments.find(
      (segment) => Number(this.selectedSegmentIds[0]) === Number(segment.id),
    )?.nameWithFallback;

    if (firstSegmentName && firstSegmentName?.length > MAX_NB_CHARS_FOR_AUDIENCES_NAME) {
      firstSegmentName = `${firstSegmentName.substring(0, MAX_NB_CHARS_FOR_AUDIENCES_NAME)}...`;
    }

    if (this.selectedSegmentIds.length === 1) {
      return firstSegmentName;
    }

    return this.intl.t('ai-agent.playground.settings.audience-filter.additional-segments', {
      segmentName: firstSegmentName,
      count: this.selectedSegmentIds.length - 1,
    });
  }

  get selectedSegmentIds() {
    return this.args.playgroundSettings?.selectedContentSegmentIds || [];
  }

  @action redirectToAudienceSettings() {
    let url = this.router.urlFor('apps.app.settings.audiences', this.appService.app.id);
    safeWindowOpen(url, '_blank');
  }

  @action applySegment(segmentId: string) {
    this.args.playgroundSettings?.addOrRemoveClickedSegment(segmentId);
  }

  @action handleDropdownClosed() {
    this.args.handleDropDownClosed?.();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground::Settings::AudienceDropdown': typeof AudienceDropdown;
    'ai-agent/playground/settings/audience-dropdown': typeof AudienceDropdown;
  }
}
