/* import __COLOCATED_TEMPLATE__ from './legacy-composer.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type Store from '@ember-data/store';
import AttributeInfoResolver from 'embercom/lib/common/template-attribute-resolver';
import {
  BaseConfig,
  BlocksDocument,
  type ComposerPublicAPI,
} from '@intercom/embercom-prosemirror-composer';
import type Guideline from 'embercom/models/ai-agent/guidelines';
import { type Paragraph } from '@intercom/interblocks.ts';
import { EMOJI_TYPEAHEAD } from '@intercom/embercom-prosemirror-composer';
import { post } from 'embercom/lib/ajax';
import { hasFeature } from 'embercom/helpers/has-feature';

class ComposerConfig extends BaseConfig {
  autoFocus = true;
  allowedBlocks = ['paragraph'];
  maxCharacterCount = 2500;
  placeholder: string;
  tools = [{ name: 'template-inserter' }, { name: 'fallback-editor' }];
  templating: { picker: string; resolver: AttributeInfoResolver };
  experimental = {
    hideInsertersOnMouseOut: true,
  };
  typeaheads = [EMOJI_TYPEAHEAD];

  constructor({ resolver, placeholder }: { resolver: AttributeInfoResolver; placeholder: string }) {
    super();
    this.templating = { picker: 'common/attribute-picker', resolver };
    this.placeholder = placeholder;
  }
}

interface Signature {
  Element: HTMLDivElement;
  Args: {
    guideline: Guideline;
    placeholder: string;
    onChange?: () => void;
    registerCancelHook: (hook: () => void) => void;
    unregisterCancelHook: (hook: () => void) => void;
  };
}

export default class LegacyComposer extends Component<Signature> {
  @service declare store: Store;
  @service declare appService: $TSFixMe;
  @service declare attributeService: $TSFixMe;

  @tracked blocksDoc: BlocksDocument;
  @tracked composer: ComposerPublicAPI | null = null;
  @tracked isTagSelectorOpen = false;

  constructor(owner: unknown, args: never) {
    super(owner, args);
    this.blocksDoc = new BlocksDocument(
      this.args.guideline.prompt
        .split('\n')
        .map((line) => ({ type: 'paragraph', text: line, class: 'no-margin' })),
    );

    if (this.args.guideline.prompt.length > 0) {
      this.args.registerCancelHook(this.setupComposer);
    }
    if (hasFeature('team-rd-services-guidance-tags', this.appService)) {
      this.loadTags();
    }
  }

  private async loadTags() {
    if (this.args.guideline.tags.length > 0) {
      return;
    }

    try {
      let response = await post(
        `/ember/ai_agent/guidelines/${this.args.guideline.id}/retrieve_guideline_tags_with_status`,
        {
          app_id: this.appService.app.id,
        },
      );

      this.args.guideline.tags = response.map((tag: any) => ({
        id: tag.id,
        name: tag.name,
        isSelected: tag.is_selected,
      }));
    } catch (error) {
      console.error('Failed to load tags:', error);
    }
  }

  @action
  setupComposer() {
    this.blocksDoc = new BlocksDocument(
      this.args.guideline.prompt
        .split('\n')
        .map((line) => ({ type: 'paragraph', text: line, class: 'no-margin' })),
    );
  }

  willDestroy() {
    super.willDestroy();
    this.args.unregisterCancelHook(this.setupComposer);
  }

  get app() {
    return this.appService.app;
  }

  get composerConfig() {
    let config = new ComposerConfig({
      resolver: new AttributeInfoResolver({
        attributes: this.app.allowedAttributes,
        conversationAttributes:
          this.attributeService.nonArchivedConversationCustomAttributesWithoutFile,
        customObjectAttributes: this.attributeService.customObjectsAttributeDescriptors,
        includeAppAttributes: false,
        includeCustomObjectAttributes: true, // needed to show conversation attributes
      }),
      placeholder: this.args.placeholder,
    });
    return config;
  }

  get hasTags() {
    return this.args.guideline.tags.length > 0;
  }

  @action
  onChange(blocksDoc: BlocksDocument) {
    this.args.guideline.prompt = blocksDoc.blocks
      .map((block: Paragraph) => block.text)
      .join('\n')
      .trim();
    this.args.onChange?.();
  }

  @action
  onReady(composer: ComposerPublicAPI) {
    this.composer = composer;
  }

  @action
  editTags() {
    this.isTagSelectorOpen = true;
  }

  @action
  closeTagSelector() {
    this.isTagSelectorOpen = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Guidance::LegacyComposer': typeof LegacyComposer;
    'ai-agent/guidance/legacy-composer': typeof LegacyComposer;
  }
}
