/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent-2 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import {
  type CustomerAnswerRating,
  CustomerRatingReason,
  type PlaygroundQuestionSource,
  type PlaygroundQuestion,
} from 'embercom/lib/fin-playground/question';
import { action } from '@ember/object';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import { objectNames } from 'embercom/models/data/matching-system/matching-constants';
import type RouterService from '@ember/routing/router-service';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import type Store from '@ember-data/store';
import type PersonalitySettings from 'embercom/models/ai-agent/tone-of-voice-settings';
import { tracked } from '@glimmer/tracking';
import { EntityType } from 'embercom/models/data/entity-types';
import { guidelineWithoutTemplateVars } from 'embercom/helpers/ai-agent-2/playground-helper';
import type LanguagesService from 'embercom/services/languages-service';

interface ViewSuggestionsArgs {
  isOpen: boolean;
  onClose: () => void;
  question: PlaygroundQuestion;
  onUpdateQuestionRating: (
    questionId: number,
    args: { rating?: CustomerAnswerRating; reason?: CustomerRatingReason },
  ) => void;
  toggleSuggestionsSideSheet: () => void;
}

type ViewSuggestionsSignature = {
  Args: ViewSuggestionsArgs;
  Element: HTMLDivElement;
};

export default class Sidebar extends Component<ViewSuggestionsSignature> {
  CustomerRatingReason = CustomerRatingReason;

  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare appService: $TSFixMe;
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare languagesService: LanguagesService;
  @service declare intercomEventService: $TSFixMe;

  @tracked personalitySettings: PersonalitySettings | null = null;

  constructor(owner: unknown, args: $TSFixMe) {
    super(owner, args);
  }

  get isStandaloneApp() {
    return this.appService.app.canUseStandalone;
  }

  trackCta(object: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object,
      rating_reason: this.args.question.customerAnswerRatingReason,
      playground_location: 'view_suggestions_sidebar',
    });
  }

  @action
  selectRatingReason(reason: CustomerRatingReason) {
    if (!this.args.question?.id) {
      return;
    }

    this.args.onUpdateQuestionRating(this.args.question.id, { reason });
  }

  @action
  createSnippet() {
    this.trackCta('create_snippet_button');
    this.knowledgeHubService.createContent(EntityType.ContentSnippet, {}, true, false);
  }

  @action
  editContent(source: PlaygroundQuestionSource) {
    this.trackCta('edit_content_button');
    safeWindowOpen(
      this.router.urlFor(
        'apps.app.knowledge-hub.view',
        objectNames[source.entityType],
        source.entityId,
      ),
      '_blank',
    );
  }

  @action
  createGuidance() {
    this.trackCta('create_guidance_button');
    if (this.isStandaloneApp) {
      safeWindowOpen(this.router.urlFor('apps.app.standalone.guidance'), '_blank');
    } else {
      safeWindowOpen(this.router.urlFor('apps.app.automation.fin-ai-agent.guidance'), '_blank');
    }
  }

  @action
  editPersonality() {
    this.trackCta('edit_personality_button');
    if (this.isStandaloneApp) {
      safeWindowOpen(this.router.urlFor('apps.app.standalone.personality'), '_blank');
    } else {
      safeWindowOpen(
        this.router.urlFor('apps.app.automation.fin-ai-agent.guidance', {
          queryParams: {
            openSectionId: 'tone-of-voice-and-answer-settings',
          },
        }),
        '_blank',
      );
    }
  }

  @action
  addMoreLanguages() {
    this.trackCta('add_more_languages_button');
    safeWindowOpen(this.router.urlFor('apps.app.settings.workspace.general'), '_blank');
  }

  @action
  goToSettings() {
    this.trackCta('multilingual_settings_button');
    safeWindowOpen(
      this.router.urlFor('apps.app.automation.fin-ai-agent.settings', {
        queryParams: {
          section: 'multilingual-settings',
        },
      }),
      '_blank',
    );
  }

  get hasGuidelines() {
    return this.args.question.appliedGuidelines.length > 0;
  }

  get guidelineContent() {
    return this.args.question.appliedGuidelines
      .map((guideline) => guideline.text.split('\n').map(guidelineWithoutTemplateVars))
      .flat();
  }

  get toneOfVoice() {
    return this.intl.t(
      `ai-agent.settings.tone-of-voice.tones.${this.args.question.aiToneOfVoice}.name`,
    );
  }

  get answerLength() {
    return this.intl.t(
      `ai-agent.settings.answer-length.values.${this.args.question.aiAnswerLength}.name`,
    );
  }

  get supportedLanguages() {
    let supportedLanguages = this.languagesService.getSupportedLocaleNames();

    supportedLanguages.unshift(
      `${this.languagesService.defaultLocaleName} (${this.intl.t('ai-agent.settings.multilingual.real-time-translation.default')})`,
    );

    return supportedLanguages.join(', ');
  }

  get isLanguageSettingsLoading() {
    return (
      this.languagesService.isLanguagesLoading ||
      this.languagesService.isMultilingualSettingsLoading
    );
  }

  get multilingualSettings() {
    return this.languagesService.multilingualSettings;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground::ViewSuggestions::Sidebar': typeof Sidebar;
  }
}
