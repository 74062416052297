/* import __COLOCATED_TEMPLATE__ from './question-box.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent-2 */

import Component from '@glimmer/component';
import type IntlService from 'ember-intl/services/intl';
import { inject as service } from '@ember/service';
import {
  CustomerAnswerRating,
  Status,
  type PlaygroundQuestion,
} from 'embercom/lib/fin-playground/question';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

interface QuestionBoxArgs {
  question: PlaygroundQuestion;
  isSelected: boolean;
  onSelect: (questionText: string) => void;
  onRemove: (question_id?: number) => void;
  shouldHaveTopBorder: boolean;
  onGenerateAnswer: () => void;
}

type QuestionBoxSignature = {
  Args: QuestionBoxArgs;
  Element: HTMLLIElement;
};

export default class AiAgentPlaygroundQuestionBox extends Component<QuestionBoxSignature> {
  @service declare intl: IntlService;

  @tracked isHovered = false;

  get stamp(): { text: string; color: 'green' | 'red' | 'gray' } | null {
    if (this.args.question.status === Status.Failed) {
      return { text: this.intl.t(`ai-agent.playground.answer-rating.failed`), color: 'red' };
    }

    if (this.args.question.status === Status.Completed) {
      switch (this.args.question.customerAnswerRating) {
        case CustomerAnswerRating.Positive:
          return {
            text: this.intl.t(`ai-agent.playground.answer-rating.positive`),
            color: 'green',
          };
        case CustomerAnswerRating.Negative:
          return { text: this.intl.t(`ai-agent.playground.answer-rating.negative`), color: 'red' };
        default:
          // ie, the rating is null
          return {
            text: this.intl.t(`ai-agent.playground.answer-rating.needs-rating`),
            color: 'gray',
          };
      }
    }

    // otherwise (eg running), don't show a stamp
    return null;
  }

  get canShowGenerateAnswerButton(): boolean {
    return this.args.question != null;
  }

  get generateAnswerButtonText(): string {
    let status = this.args.question?.status;
    if (status === Status.Pending) {
      return this.intl.t('ai-agent.playground.generate-answer-pending');
    } else {
      return this.intl.t('ai-agent.playground.generate-answer');
    }
  }

  get isGenerateAnswerButtonDisabled(): boolean {
    return this.args.question?.status === Status.Running;
  }

  get questionOptionsList() {
    let defaultList = [
      {
        text: this.intl.t('ai-agent.playground.delete-question'),
        icon: 'trash',
        onSelectItem: this.deleteQuestion,
        isDisabled: false,
      },
    ];

    if (this.canShowGenerateAnswerButton) {
      defaultList.unshift({
        text: this.generateAnswerButtonText,
        icon: 'sync',
        onSelectItem: this.args.onGenerateAnswer,
        isDisabled: this.isGenerateAnswerButtonDisabled,
      });
    }

    return defaultList;
  }

  @action
  deleteQuestion() {
    this.args.onRemove(this.args.question.id);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground::QuestionBox': typeof AiAgentPlaygroundQuestionBox;
  }
}
