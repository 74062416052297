/* import __COLOCATED_TEMPLATE__ from './function-call-conversation-item.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import Component from '@glimmer/component';
import type { FormattedConversationFunctionCall } from './conversation-item';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';

export interface ConversationArgs {
  conversationItem: FormattedConversationFunctionCall;
  verboseLogsEnabled: boolean;
}

interface Signature {
  Args: ConversationArgs;
  Element: HTMLElement;
}

export default class FunctionCallConversationItem extends Component<Signature> {
  @service declare intl: IntlService;

  get formattedToolName() {
    let isLoading = this.args.conversationItem.status === 'loading';

    switch (this.args.conversationItem.toolName) {
      case 'search_help_center': {
        return isLoading
          ? this.intl.t(
              'ai-agent.voice-playground.conversation.function-calls.searching-help-center',
            )
          : this.intl.t(
              'ai-agent.voice-playground.conversation.function-calls.searched-help-center',
            );
      }
      default:
        return isLoading
          ? this.intl.t('ai-agent.voice-playground.conversation.calling-tool', {
              toolName: this.args.conversationItem.toolName,
            })
          : this.intl.t('ai-agent.voice-playground.conversation.tool-called', {
              toolName: this.args.conversationItem.toolName,
            });
    }
  }

  get formattedToolArguments() {
    switch (this.args.conversationItem.toolName) {
      case 'search_help_center': {
        let query = this.args.conversationItem.toolArguments.query;

        if (this.args.conversationItem.toolArguments.rephrased_querys?.length >= 3) {
          let [arg1, arg2, arg3] = this.args.conversationItem.toolArguments.rephrased_querys;

          return this.intl.t(
            'ai-agent.voice-playground.conversation.function-calls.search-query-related-to-multiple',
            {
              arg1,
              arg2,
              arg3,
            },
          );
        }

        return this.intl.t(
          'ai-agent.voice-playground.conversation.function-calls.search-query-related-to-single',
          {
            query,
          },
        );
      }
      default:
        return this.intl.t(
          'ai-agent.voice-playground.conversation.function-calls.unknown-tool-arguments',
          {
            toolArguments: this.args.conversationItem.toolArguments,
          },
        );
    }
  }

  get formattedToolOutput() {
    switch (this.args.conversationItem.toolName) {
      case 'search_help_center': {
        let toolOutput = this.args.conversationItem.toolOutput;

        if (!toolOutput) {
          return this.intl.t(
            'ai-agent.voice-playground.conversation.function-calls.search-help-center-output-not-found',
          );
        }

        try {
          toolOutput = JSON.parse(toolOutput);

          if (Array.isArray(toolOutput) && toolOutput.length > 1) {
            return this.intl.t(
              'ai-agent.voice-playground.conversation.function-calls.search-help-center-output',
              {
                contentSources: toolOutput.length,
              },
            );
          }
        } catch (error) {
          console.error('Error parsing tool output:', error);
        }

        return this.intl.t(
          'ai-agent.voice-playground.conversation.function-calls.search-help-center-output-unknown-count',
        );
      }
      default:
        return this.intl.t('ai-agent.voice-playground.conversation.tool-output', {
          toolOutput: this.args.conversationItem.toolOutput
            ? this.args.conversationItem.toolOutput
            : this.intl.t('ai-agent.voice-playground.conversation.tool-output-empty'),
        });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::VoicePlayground::FunctionCallConversationItem': typeof FunctionCallConversationItem;
  }
}
