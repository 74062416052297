/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import templateOnlyComponent from '@ember/component/template-only';

export interface HeaderArgs {
  title: string;
  showSidebarToggle: boolean;
  isSideMenuOpen: boolean;
  openSideMenu: () => void;
}

interface Signature {
  Args: HeaderArgs;
  Element: HTMLElement;
  Blocks: {
    default: [];
  };
}

const Header = templateOnlyComponent<Signature>();
export default Header;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::VoicePlayground::Header': typeof Header;
  }
}
