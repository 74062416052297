/* import __COLOCATED_TEMPLATE__ from './legacy-tag-selector.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
//@ts-ignore
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';
import type Guideline from 'embercom/models/ai-agent/guidelines';
import type { GuidelineTag } from 'embercom/models/ai-agent/guidelines';

interface Args {
  isOpen: boolean;
  onClose: () => void;
  guideline: Guideline;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

export default class LegacyTagSelector extends Component<Signature> {
  @tracked tags: GuidelineTag[];
  @tracked searchQuery = '';

  constructor(owner: unknown, args: Signature['Args']) {
    super(owner, args);
    this.tags = args.guideline.tags;
    this.sortTags();
  }

  private sortTags(): void {
    this.tags = [...this.tags].sort((a, b) => {
      if (a.isSelected !== b.isSelected) {
        return a.isSelected ? -1 : 1;
      }
      return a.name.localeCompare(b.name);
    });
  }

  get filteredTags() {
    if (!this.searchQuery) {
      return this.tags;
    }

    return this.tags.filter((tag) =>
      tag.name.toLowerCase().includes(this.searchQuery.toLowerCase()),
    );
  }

  @action
  highlightText(text: string) {
    if (!this.searchQuery) {
      return sanitizeHtml(text);
    }

    let escapedSearch = this.searchQuery.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    let regex = new RegExp(`(${escapedSearch})`, 'gi');
    let highlighted = text.replace(
      regex,
      '<mark class="bg-neutral-highlight font-normal">$1</mark>',
    );
    return sanitizeHtml(highlighted);
  }

  @action
  updateSearch(event: InputEvent) {
    let target = event.target as HTMLInputElement;
    this.searchQuery = target.value;
  }

  @action
  toggleTag(tag: GuidelineTag) {
    let updatedTag = { ...tag, isSelected: !tag.isSelected };
    this.tags = this.tags.map((t) => (t.id === tag.id ? updatedTag : t));
    this.searchQuery = '';
  }

  @action
  closeModal() {
    this.sortTags();
    this.searchQuery = '';
    this.args.guideline.tags = this.tags;
    this.args.onClose();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Guidance::LegacyTagSelector': typeof LegacyTagSelector;
  }
}
