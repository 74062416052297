/* import __COLOCATED_TEMPLATE__ from './inspect-sources.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent-2 */

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import type IntlService from 'ember-intl/services/intl';
import { objectIcons } from 'embercom/models/data/matching-system/matching-constants';
import {
  type AppliedGuideline,
  type PlaygroundQuestionSource,
} from 'embercom/lib/fin-playground/question';
import { KNOWLEDGE_HUB_ENTITIES_NAMES } from 'embercom/lib/knowledge-hub/constants';

enum InspectItemType {
  Personality = 'personality',
  Content = 'content',
  Guidance = 'guidance',
}

interface InspectItem {
  id: string;
  type: InspectItemType;
  icon: InterfaceIconName;
  title: string;
  isDeleted?: boolean;
  link?: {
    route: string;
    query?: $TSFixMe;
    models?: $TSFixMe;
  };
}

interface Signature {
  Args: {
    aiToneOfVoice?: 'friendly' | 'neutral' | 'plainspoken' | 'professional' | 'playful';
    aiAnswerLength?: 'succinct' | 'standard' | 'comprehensive';
    contentSources?: PlaygroundQuestionSource[];
    appliedGuidelines?: AppliedGuideline[];
  };
}

export default class InspectSources extends Component<Signature> {
  @service declare session: Session;
  @service declare appService: any;
  @service declare intl: IntlService;

  @tracked openSectionId: string | null = null;

  entityName(entityType: keyof typeof KNOWLEDGE_HUB_ENTITIES_NAMES) {
    return KNOWLEDGE_HUB_ENTITIES_NAMES[entityType];
  }

  get guidanceIcon(): InterfaceIconName {
    return 'wave';
  }

  get toneOfVoiceIcon(): InterfaceIconName {
    switch (this.args.aiToneOfVoice) {
      case 'friendly':
        return 'wave';
      case 'neutral':
        return 'fin';
      case 'plainspoken':
        return 'book';
      case 'professional':
        return 'newspaper';
      case 'playful':
        return 'lwr-happy';
      default:
        return 'lwr-happy';
    }
  }

  get answerLengthIcon(): InterfaceIconName {
    switch (this.args.aiAnswerLength) {
      case 'succinct':
        return 'short-text';
      case 'standard':
        return 'long-text';
      case 'comprehensive':
        return 'extra-long-text';
      default:
        return 'extra-long-text';
    }
  }

  get personalitySettings(): InspectItem[] {
    let settings: InspectItem[] = [];

    if (this.args.aiToneOfVoice) {
      settings.push({
        id: 'tone-of-voice',
        type: InspectItemType.Personality,
        icon: this.toneOfVoiceIcon,
        title: this.intl.t(`ai-agent.playground.tone-of-voice.${this.args.aiToneOfVoice}`),
      });
    }

    if (this.args.aiAnswerLength) {
      settings.push({
        id: 'answer-length',
        type: InspectItemType.Personality,
        icon: this.answerLengthIcon,
        title: this.intl.t(`ai-agent.playground.answer-length.${this.args.aiAnswerLength}`),
      });
    }

    return settings;
  }

  get contentSources(): InspectItem[] {
    return (this.args.contentSources || []).map((source) => ({
      id: source.entityId.toString(),
      type: InspectItemType.Content,
      icon: objectIcons[source.entityType],
      title: source.title,
      isDeleted: source.deleted,
      link: {
        route: 'apps.app.knowledge-hub.view',
        models: [this.session.workspace, this.entityName(source.entityType), source.entityId],
      },
    }));
  }

  get allItems(): InspectItem[] {
    return [
      ...this.contentSources,
      ...(this.args.appliedGuidelines || []).map((guideline) => ({
        id: guideline.id,
        type: InspectItemType.Guidance,
        icon: this.guidanceIcon,
        title: guideline.title,
        link: {
          route: this.isStandaloneApp
            ? 'apps.app.standalone.guidance'
            : 'apps.app.automation.fin-ai-agent.guidance',
        },
      })),
      ...this.personalitySettings.map((setting) => ({
        ...setting,
        link: {
          route: this.isStandaloneApp
            ? 'apps.app.standalone.personality'
            : 'apps.app.automation.fin-ai-agent.guidance',
          query: this.isStandaloneApp ? {} : { openSectionId: 'tone-of-voice-and-answer-settings' },
        },
      })),
    ];
  }

  get isStandaloneApp(): boolean {
    return this.appService.app.canUseStandalone;
  }

  get firstThreeItems(): InspectItem[] {
    return this.allItems.slice(0, 3);
  }

  get hiddenItems(): InspectItem[] {
    return this.allItems.slice(3);
  }

  get shouldShowAccordion(): boolean {
    return this.allItems.length > 3;
  }

  @action
  toggleAccordion() {
    this.openSectionId = this.openSectionId ? null : 'inspect-sources';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground::FinAnswer::InspectSources': typeof InspectSources;
    'ai-agent/playground/fin-answer/inspect-sources': typeof InspectSources;
  }
}
