/* import __COLOCATED_TEMPLATE__ from './suggestion-box.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent-2 */
import Component from '@glimmer/component';

type SuggestionBoxSignature = {
  Args: {
    icon:
      | 'fin'
      | 'hand'
      | 'rules'
      | 'language'
      | 'new'
      | 'article'
      | 'long-text'
      | 'short-text'
      | 'wave'
      | 'note'
      | 'globe';
    title: string;
    description: string | string[];
    buttonLabel?: string;
    onClick: () => void;
  };
};

export default class SuggestionBox extends Component<SuggestionBoxSignature> {
  get descriptionLines() {
    return Array.isArray(this.args.description) ? this.args.description : [this.args.description];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground::ViewSuggestions::SuggestionBox': typeof SuggestionBox;
  }
}
