/* import __COLOCATED_TEMPLATE__ from './deploy-timeline.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type ContentWrapper from 'embercom/models/content-service/content-wrapper';
import type { ChannelType } from 'embercom/lib/workflows/fin-workflow-preview';
import type AiAgentSetupService from 'embercom/services/ai-agent-setup-service';
import { states } from 'embercom/models/data/matching-system/matching-constants';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import { CONVERSATION_STARTED_TARGET } from 'embercom/lib/operator/custom-bots/constants';
import type Guideline from 'embercom/models/ai-agent/guidelines';
import type Settings from 'embercom/models/ai-agent/tone-of-voice-settings';
import type Store from '@ember-data/store';
import type RouterService from '@ember/routing/router-service';
export type FinChannel =
  | 'web'
  | 'ios'
  | 'android'
  | 'facebook'
  | 'whatsapp'
  | 'instagram'
  | 'sms'
  | 'email'
  | 'phone';
export const FIN_CHAT_CHANNELS = [
  'web',
  'ios',
  'android',
  'facebook',
  'whatsapp',
  'instagram',
  'sms',
];

const EMAIL_CHANNEL = 'email';

interface Signature {
  Element: HTMLDivElement;
  Args: Arguments;
}

interface Arguments {
  channelType: string;
}
export default class DeployTimeline extends Component<Signature> {
  @service declare appService: any;
  @tracked declare previewConfiguration: $TSFixMe;
  @tracked declare finSetupRuleset: $TSFixMe;
  @service declare outboundHomeService: $TSFixMe;
  @tracked declare liveWorkflowsOverridableByFinSetup: ContentWrapper[];
  @service declare aiAgentSetupService: AiAgentSetupService;
  @service declare store: Store;
  @tracked personalityCustomizationSettings?: Settings;
  liveInboundWorkflowsOverridableByFinSetup: ContentWrapper[] = [];
  liveConversationStartedWorkflowsOverridableByFinSetup: ContentWrapper[] = [];
  @tracked declare phoneSetupRuleset: $TSFixMe;
  @tracked isFetching = false;
  @tracked previewLocale = 'en';
  @service declare router: RouterService;

  constructor(owner: unknown, args: any) {
    super(owner, args);
    this.loadAll();
    this.previewLocale = this.appService.app.locale || 'en';
  }

  async loadAll() {
    this.isFetching = true;

    let promises = [this.loadAiAgentSetupService(), this.loadWorkflowsOverridableByFinSetup()];

    if (this.args.channelType !== 'phone') {
      promises.push(this.getPreviewConfiguration());
      promises.push(this.getPersonalityCustomizationSettings());
    }

    await Promise.all(promises);
    this.isFetching = false;
  }

  async getPersonalityCustomizationSettings(): Promise<$TSFixMe> {
    this.personalityCustomizationSettings = await this.store.findRecord(
      'ai-agent/tone-of-voice-settings',
      this.appService.app.id,
    );
  }

  get guidelines(): Guideline[] {
    return this.store.peekAll('ai-agent/guidelines').toArray();
  }

  get isLoading() {
    return (
      !this.finSetupRuleset ||
      !this.liveWorkflowsOverridableByFinSetup ||
      (this.args.channelType !== 'phone' && !this.previewConfiguration) ||
      this.isFetching
    );
  }

  get parentAppUrl(): string {
    return this.router.urlFor(
      'apps.app.automation.fin-ai-agent.setup',
      this.appService.app.parent_app_id,
    );
  }

  async getPreviewConfiguration(): Promise<$TSFixMe> {
    let settings = await this.store.findRecord('messenger-settings/all', this.appService.app.id);
    this.previewConfiguration = settings.previewConfiguration;
  }

  async loadWorkflowsOverridableByFinSetup() {
    await this.loadLiveInboundWorkflowsOverridableByFinSetup();
    await this.loadLiveConversationStartedWorkflowsOverridableByFinSetup();
    this.liveWorkflowsOverridableByFinSetup = [
      ...this.liveInboundWorkflowsOverridableByFinSetup,
      ...this.liveConversationStartedWorkflowsOverridableByFinSetup,
    ];
  }

  async loadAiAgentSetupService() {
    await this.aiAgentSetupService.load();
    switch (this.args.channelType) {
      case 'chat':
        this.finSetupRuleset = this.aiAgentSetupService.setupRuleset.finSetupRuleset;
        break;
      case 'email':
        this.finSetupRuleset = this.aiAgentSetupService.setupRuleset.emailSetupRuleset;
        break;
      case 'phone':
        this.finSetupRuleset = this.aiAgentSetupService.setupRuleset.phoneSetupRuleset;
        break;
      default:
        console.warn(`Invalid channel type: ${this.args.channelType}`);
    }
  }

  async loadLiveConversationStartedWorkflowsOverridableByFinSetup() {
    if (!this.appService.app.canUseFeature('bot_workflows')) {
      return;
    }

    let workflows = await this.outboundHomeService.contentSearchWithFilters({
      object_types: [objectTypes.triggerableCustomBot],
      app_id: this.appService.app.id,
      additionalSearchableData: {
        target: CONVERSATION_STARTED_TARGET,
        has_fin: true,
      },
    });

    this.liveConversationStartedWorkflowsOverridableByFinSetup = (
      workflows.contentWrappers || []
    ).filter((wrapper: ContentWrapper) => wrapper.contents.firstObject?.state === states.live);
  }

  async loadLiveInboundWorkflowsOverridableByFinSetup() {
    if (!this.appService.app.canUseFeature('bot_workflows')) {
      return;
    }

    let workflows = await this.outboundHomeService.contentSearchWithFilters({
      object_types: [objectTypes.inboundCustomBot],
      app_id: this.appService.app.id,
      additionalSearchableData: {
        has_fin: true,
      },
    });

    this.liveInboundWorkflowsOverridableByFinSetup = (workflows.contentWrappers || []).filter(
      (wrapper: ContentWrapper) => wrapper.contents.firstObject?.state === states.live,
    );
  }

  get liveWorkflowsOverridableByFinChatSetup(): ContentWrapper[] {
    return this.liveWorkflowsOverridableByFinSetup.filter((workflow: ContentWrapper) => {
      return this.workflowHasRelevantChannels(FIN_CHAT_CHANNELS, workflow);
    });
  }
  get liveWorkflowsOverridableByFinEmailSetup(): ContentWrapper[] {
    return this.liveWorkflowsOverridableByFinSetup.filter((workflow: ContentWrapper) => {
      return this.workflowHasRelevantChannels([EMAIL_CHANNEL], workflow);
    });
  }
  private workflowHasRelevantChannels(targetChannels: string[], workflow: ContentWrapper): boolean {
    return workflow.contents.firstObject?.contentData.target_channels?.some((channel: FinChannel) =>
      targetChannels.includes(channel),
    );
  }
  @action
  async reloadSetupRuleset(setupType: ChannelType) {
    return this.aiAgentSetupService.loadSetupRuleset(setupType);
  }

  get hasContentReadyForFin(): boolean {
    return this.aiAgentSetupService.hasContentReadyForFin;
  }

  get liveFinWorkflowsOverridableBySetup(): any {
    return this.args.channelType === 'chat'
      ? this.liveWorkflowsOverridableByFinSetup
      : this.liveWorkflowsOverridableByFinEmailSetup;
  }

  get channelType(): ChannelType {
    return this.args.channelType as ChannelType;
  }

  @action
  sendMessageToMessengerIFrame() {
    let message: {
      type: string;
      previewGuidelines?: { text: string; category: string }[];
      previewPersonalitySettings?: Settings;
    } = {
      type: 'preview-settings-updated',
      previewGuidelines: this.guidelines
        .filter((guideline) => !guideline.isDeleted)
        .map((guideline) => {
          return {
            text: guideline.prompt,
            category: guideline.category,
          };
        }),
      previewPersonalitySettings: this.personalityCustomizationSettings,
    };

    let iframe = window.document.querySelector(
      '#hosted-messenger-fin-demo-preview',
    ) as HTMLIFrameElement;

    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(JSON.stringify(message));
    }
  }

  @action
  setPreviewLocale(locale: string) {
    this.previewLocale = locale;
  }

  get previewableWorkflow(): string {
    return this.finSetupRuleset.rulesetLinks.firstObject.object;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAutomation::Deploy::DeployTimeline': typeof DeployTimeline;
    'ai-automation/deploy/deploy-timeline': typeof DeployTimeline;
  }
}
