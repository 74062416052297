/* import __COLOCATED_TEMPLATE__ from './voice-playground.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type FinVoiceService from 'embercom/services/fin-voice-service';
import { tracked } from '@glimmer/tracking';
import type { FinVoiceVadSettings } from 'embercom/objects/ai-agent/fin-voice/fin-voice-server-events';
import type { FinVoiceVoice } from 'embercom/objects/ai-agent/fin-voice/fin-voice-voice-options';
import type { FinVoicePipeline } from 'embercom/objects/ai-agent/fin-voice/fin-voice-pipeline-options';
import type { AudioDeviceSettings } from 'embercom/services/fin-voice-service';
import type IntlService from 'ember-intl/services/intl';

export interface AiAgentVoicePlaygroundArgs {
  isSideMenuOpen?: boolean;
  closeSideMenu?: () => void;
}

interface Signature {
  Args: AiAgentVoicePlaygroundArgs;
  Element: HTMLElement;
}
export default class AiAgentVoicePlayground extends Component<Signature> {
  @service declare finVoiceService: FinVoiceService;
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;

  readonly LOG_TAB = 'logs';
  readonly SETTINGS_TAB = 'settings';

  @tracked sideMenuContent: typeof this.LOG_TAB | typeof this.SETTINGS_TAB = this
    .canUseFinVoicePlaygroundDev
    ? this.LOG_TAB
    : this.SETTINGS_TAB;

  get isSettingsOpen() {
    return this.sideMenuContent === this.SETTINGS_TAB;
  }

  get isLogsVisible() {
    return this.sideMenuContent === this.LOG_TAB;
  }

  get isMuted() {
    return this.finVoiceService.isMuted;
  }

  get connectionState() {
    return this.finVoiceService.websocketState;
  }

  get isDisconnected() {
    return this.finVoiceService.websocketState === 'disconnected';
  }

  get sessions() {
    return this.finVoiceService.sessions;
  }

  get hasSessions() {
    return !!this.sessions?.length;
  }

  get vadSettings() {
    return this.finVoiceService.vadSettings;
  }

  get selectedVoice() {
    return this.finVoiceService.selectedVoice;
  }

  get selectedPipeline() {
    return this.finVoiceService.selectedPipeline;
  }

  get audioDeviceSettings() {
    return this.finVoiceService.audioDeviceSettings;
  }

  get canUseFinVoicePlaygroundDev() {
    return this.appService.app.canUseFinVoicePlaygroundDev;
  }

  get verboseLogsEnabled() {
    return this.finVoiceService.verboseLogsEnabled;
  }

  constructor(owner: unknown, args: any) {
    super(owner, args);
  }

  willDestroy() {
    super.willDestroy();
    this.disconnect();
  }

  @action
  connect() {
    return this.finVoiceService.connectStream();
  }

  @action
  disconnect() {
    return this.finVoiceService.disconnectStream();
  }

  @action closeSideMenu() {
    if (this.args.closeSideMenu) {
      this.args.closeSideMenu();
    }
  }

  @action
  mute() {
    return this.finVoiceService.muteAudio();
  }

  @action
  unmute() {
    return this.finVoiceService.unmuteAudio();
  }

  @action
  sendTextMessage(message: string) {
    return this.finVoiceService.sendTextMessage(message);
  }

  @action
  saveSettings(
    vadSettings: FinVoiceVadSettings,
    voice: FinVoiceVoice,
    pipeline: FinVoicePipeline,
    audioDeviceSettings: AudioDeviceSettings,
  ) {
    this.finVoiceService.vadSettings = vadSettings;
    this.finVoiceService.selectedVoice = voice;
    this.finVoiceService.selectedPipeline = pipeline;
    this.finVoiceService.audioDeviceSettings = audioDeviceSettings;
  }

  @action
  updateVadSetting(key: keyof FinVoiceVadSettings, value: number) {
    this.finVoiceService.vadSettings = {
      ...this.finVoiceService.vadSettings,
      [key]: value,
    };
  }

  @action
  updateAudioDeviceSetting(key: keyof AudioDeviceSettings, value: boolean) {
    this.finVoiceService.audioDeviceSettings = {
      ...this.finVoiceService.audioDeviceSettings,
      [key]: value,
    };
  }

  @action
  updateVoice(voice: FinVoiceVoice) {
    this.finVoiceService.selectedVoice = voice;
  }

  @action
  updatePipeline(pipeline: FinVoicePipeline) {
    this.finVoiceService.selectedPipeline = pipeline;
  }

  @action
  clearAllSessions() {
    return this.finVoiceService.clearAllSessions();
  }

  @action
  getFrequencyData() {
    return this.finVoiceService.getFrequencyData();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::VoicePlayground': typeof AiAgentVoicePlayground;
  }
}
