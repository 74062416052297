/* import __COLOCATED_TEMPLATE__ from './learn-button.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';

export default class LearnButtonComponent extends Component {
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;

  get learnArticles() {
    if (this.appService.app.isStandaloneApp) {
      return [
        {
          items: [
            {
              text: this.intl.t('ai-agent.guidance.how-to-use-article'),
              icon: 'article',
              onSelectItem: () => {
                window.Intercom('showArticle', 10644329);
              },
            },
            {
              text: this.intl.t('ai-agent.guidance.best-practices-article'),
              icon: 'article',
              onSelectItem: () => {
                window.Intercom('showArticle', 10644781);
              },
            },
          ],
        },
      ];
    }
    return [
      {
        items: [
          {
            text: this.intl.t('ai-agent.guidance.how-to-use-article'),
            icon: 'article',
            onSelectItem: () => {
              window.Intercom('showArticle', 10210126);
            },
          },
          {
            text: this.intl.t('ai-agent.guidance.best-practices-article'),
            icon: 'article',
            onSelectItem: () => {
              window.Intercom('showArticle', 10560969);
            },
          },
          {
            text: this.intl.t('ai-agent.guidance.fin-tone-and-identity-article'),
            icon: 'article',
            onSelectItem: () => {
              window.Intercom('showArticle', 9794969);
            },
          },
        ],
      },
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Guidance::LearnButton': typeof LearnButtonComponent;
    'ai-agent/guidance/learn-button': typeof LearnButtonComponent;
  }
}
