/* import __COLOCATED_TEMPLATE__ from './alias-icon.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type AdminSummary from 'embercom/objects/inbox/admin-summary';

interface Args {
  admin?: AdminSummary;
}

interface Signature {
  Args: Args;
  Element: HTMLDivElement;
}

export default class AliasIcon extends Component<Signature> {
  @service declare intl: IntlService;

  private get isAliasEmailMissing() {
    return this.args.admin != null && this.args.admin?.aliasEmail == null;
  }

  get tooltipContent() {
    if (this.isAliasEmailMissing) {
      return this.intl.t('components.admin.about-component.alias.email.visible-to-user.live');
    } else {
      return this.intl.t('components.admin.about-component.alias.status');
    }
  }

  get bgColour() {
    return this.isAliasEmailMissing ? 'bg-notice-container' : 'bg-success-container';
  }

  get fillColour() {
    return this.isAliasEmailMissing ? 'text-on-notice' : 'success-fill';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    AliasIcon: typeof AliasIcon;
  }
}
