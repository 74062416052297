/* import __COLOCATED_TEMPLATE__ from './confirm-delete-questions-modal.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent-2 */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    onModalClose: () => void;
    deleteQuestions: () => void;
    countOfBulkSelectedQuestions: number;
  };
}

const ConfirmDeleteQuestionsModal = templateOnlyComponent<Signature>();
export default ConfirmDeleteQuestionsModal;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground::Modals::ConfirmDeleteQuestionsModal': typeof ConfirmDeleteQuestionsModal;
  }
}
