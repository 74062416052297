/* import __COLOCATED_TEMPLATE__ from './unresolved-questions.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type AiAgentSetupService from 'embercom/services/ai-agent-setup-service';
import { get } from 'embercom/lib/ajax';
import { action } from '@ember/object';

interface Args {}

export default class UnresolvedQuestions extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare aiAgentSetupService: AiAgentSetupService;
  @tracked isLoading = true;
  @tracked latestQuestionExtractionRunId = 0;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.loadData();
  }

  async loadData() {
    this.isLoading = true;
    let promises = [this.aiAgentSetupService.load(), this.loadLastQuestionGroupsRunDetails()];
    await Promise.all(promises);
    this.isLoading = false;
  }
  async loadLastQuestionGroupsRunDetails(): Promise<$TSFixMe> {
    let response = await get(
      '/ember/ai_agent/question_groups/latest_question_extraction_run_details',
      {
        app_id: this.appService.app.id,
      },
    );
    this.latestQuestionExtractionRunId = response.id;
  }
  get finIsLive() {
    return this.aiAgentSetupService.isFinLive;
  }
  @action
  reloadModel() {
    this.loadData();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAutomation::Analyze::UnresolvedQuestions': typeof UnresolvedQuestions;
    'ai-automation/analyze/UnresolvedQuestions': typeof UnresolvedQuestions;
  }
}
