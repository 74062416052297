/* import __COLOCATED_TEMPLATE__ from './performance.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type ReportingTemplates from 'embercom/services/reporting-templates';
import type CustomReportsService from 'embercom/services/custom-reports-service';
import type Report from 'embercom/models/reporting/custom/report';
import { tracked } from '@glimmer/tracking';
import type ReportingViews from 'embercom/services/reporting-views';
import { taskFor } from 'ember-concurrency-ts';
import type RouterService from '@ember/routing/router-service';
import { filterableProperties } from 'embercom/lib/reporting/custom/filter-helpers';
import { action } from '@ember/object';

interface Args {}

export default class Performance extends Component<Args> {
  @service declare reportingTemplates: ReportingTemplates;
  @service declare customReportsService: CustomReportsService;
  @service declare reportingViews: ReportingViews;
  @service declare store: $TSFixMe;
  @service declare router: RouterService;

  @tracked report?: Report;
  @tracked isLoading = true;
  @tracked finAIAgentReportId: string | undefined;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.loadReport();
  }

  get serializedReport() {
    return {
      id: 'fin_ai_agent',
    };
  }

  get reportDefinition() {
    let reportDefinition = this.reportingTemplates.rawReportTemplatesById['fin_ai_agent'];
    return {
      ...reportDefinition,
      charts: reportDefinition.charts.filter(
        (chart: $TSFixMe) => chart.visualization_type !== 'banner',
      ),
    };
  }

  get filterableProperties() {
    return [...filterableProperties(), 'teammate', 'team', 'conversation.replied.workflow_ids'];
  }

  async loadReport() {
    await taskFor(this.reportingViews.setUp).perform();
    let allReports = this.reportingViews.allReports;
    this.finAIAgentReportId = allReports.find(
      (report) =>
        report.name === 'Fin AI Agent' &&
        !report.isStaticReport &&
        typeof Number(report.id) === 'number',
    )?.id;
    await this.reportingTemplates.loadTemplates();
    this.isLoading = false;
  }

  @action
  goToReports() {
    return this.finAIAgentReportId
      ? this.router.transitionTo(
          'apps.app.reports.custom-reports.report.show',
          this.finAIAgentReportId,
        )
      : this.router.transitionTo('apps.app.reports.overview');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAutomation::Analyze::Performance': typeof Performance;
    'ai-automation/analyze/Performance': typeof Performance;
  }
}
