/* import __COLOCATED_TEMPLATE__ from './questions-loading-state.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent-2 */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {};
}

const QuestionsLoadingState = templateOnlyComponent<Signature>();
export default QuestionsLoadingState;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground::LoadingStates::QuestionsLoadingState': typeof QuestionsLoadingState;
  }
}
