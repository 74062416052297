/* import __COLOCATED_TEMPLATE__ from './submenu-nav.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type AiAgentSetupService from 'embercom/services/ai-agent-setup-service';
interface MenuItem {
  title: string;
  icon?: string;
  url?: string;
  items?: MenuItem[];
  shouldHide?: boolean;
  stamp?: string;
  count?: number;
  external?: boolean;
  isSeparator?: boolean;
  status?: string;
}

const NEW_SUBMENU_START_DATE = '2025-03-19';

const MENU_STRUCTURE = (
  app: any,
  profileCount: number,
  customAnswersCount: number,
  contentSuggestionsCount: number,
  chatStatus: string,
  emailStatus: string,
  phoneStatus: string,
): MenuItem[] => [
  {
    title: 'ai-agent.new-submenu.analyze',
    icon: 'column-chart',
    items: [
      {
        title: 'ai-agent.new-submenu.performance',
        url: 'apps.app.automation.fin-ai-agent.analyze.performance',
      },
      {
        title: 'ai-agent.new-submenu.ai-insights',
        url: app.canSeeAiInsightsTopicsV1
          ? 'apps.app.automation.insights-v3-monitor'
          : 'apps.app.automation.fin-ai-agent.analyze.ai-insights',
      },
      {
        title: 'ai-agent.new-submenu.conversations',
        external: true,
        url: 'inbox.workspace.inbox.fin-view-redirect',
      },
      {
        title: 'ai-agent.new-submenu.unresolved-questions',
        url: 'apps.app.automation.fin-ai-agent.analyze.unresolved-questions',
      },
    ],
  },
  {
    title: 'ai-agent.new-submenu.train',
    icon: 'article',
    items: [
      {
        title: 'ai-agent.new-submenu.content',
        url: 'apps.app.automation.fin-ai-agent.content',
      },
      {
        title: 'ai-agent.new-submenu.guidance',
        url: 'apps.app.automation.fin-ai-agent.guidance',
        shouldHide: !app.canUseFinGuidance,
      },
      {
        title: 'operator.sub-menu.fin-ai-agent.tasks',
        url: 'apps.app.automation.fin-ai-agent.tasks.index',
      },
      {
        title: 'ai-agent.new-submenu.custom-answers',
        url: 'apps.app.automation.fin-ai-agent.custom-answers.index',
        count: customAnswersCount,
        shouldHide: app.created_at > new Date(NEW_SUBMENU_START_DATE),
      },
      {
        title: 'ai-agent.new-submenu.ai-categories',
        url: 'apps.app.automation.fin-ai-agent.ai-categories',
        shouldHide: !app.canUseFinAiCategories,
      },
      {
        title: 'ai-agent.new-submenu.content-suggestions',
        url: 'apps.app.automation.fin-ai-agent.content-suggestions',
        count: contentSuggestionsCount,
      },
    ],
  },
  {
    title: 'ai-agent.new-submenu.test',
    icon: 'test',
    url: 'apps.app.automation.fin-ai-agent.testing.index',
    shouldHide: !app.canUseFinPlayground,
  },
  {
    title: 'ai-agent.new-submenu.deploy',
    icon: 'rocket-ship',
    items: [
      {
        title: 'ai-agent.new-submenu.chat',
        url: 'apps.app.automation.fin-ai-agent.deploy.chat',
        status: chatStatus,
      },
      {
        title: 'ai-agent.new-submenu.email',
        url: 'apps.app.automation.fin-ai-agent.deploy.email',
        status: emailStatus,
      },
      {
        title: 'ai-agent.new-submenu.phone',
        url: 'apps.app.automation.fin-ai-agent.deploy.phone',
        status: phoneStatus,
      },
      {
        title: 'ai-agent.new-submenu.profiles',
        url: 'apps.app.automation.fin-ai-agent.profiles.index',
        count: profileCount,
        shouldHide: profileCount === 0,
      },
    ],
  },
  {
    title: 'ai-agent.new-submenu.settings',
    url: 'apps.app.automation.fin-ai-agent.settings',
    icon: 'settings',
  },
  {
    title: '',
    isSeparator: true,
  },
  {
    title: 'ai-agent.new-submenu.workflows',
    icon: 'workflows',
    url: 'apps.app.automation.workflows-overview',
  },
  {
    title: 'ai-agent.new-submenu.simple-automations',
    url: 'apps.app.automation.basics',
    icon: 'trigger',
  },
];
interface Signature {
  profileCount: number;
  customAnswersCount: number;
  contentSuggestionsCount: number;
}
export default class SubmenuNav extends Component<Signature> {
  @service appService: any;
  @service router: any;
  @service declare aiAgentSetupService: AiAgentSetupService;
  // The active section is the one where the page we currently are resides
  @tracked activeSection: any =
    this.menuItems.find((item: any) => this.checkIfSectionIsCurrentRoute(item))?.title || '';
  // we want to be able to open 2 sections, the active one and another one only
  @tracked secondaryOpenSection: any = '';
  @tracked isLoading = true;
  checkIfSectionIsCurrentRoute(section: any) {
    return (
      this.router.currentRouteName === section.url ||
      section.items?.any(
        (item: any) =>
          this.router.currentURL
            .split('?')[0]
            .contains?.(this.router.urlFor(item.url, this.appService.app.id)) ||
          this.router.currentRouteName === item.url,
      )
    );
  }

  constructor(owner: any, args: any) {
    super(owner, args);
    this.router.on('routeDidChange', this.updateActiveSection);
    this.load();
  }

  async load() {
    this.isLoading = true;
    await this.aiAgentSetupService.load();
    this.isLoading = false;
  }

  getActiveSection() {
    return this.menuItems.find((item: any) => this.checkIfSectionIsCurrentRoute(item))?.title || '';
  }

  willDestroy() {
    super.willDestroy();
    this.router.off('routeDidChange', this.updateActiveSection);
  }

  @action
  updateActiveSection() {
    this.activeSection =
      this.menuItems.find((item: any) => this.checkIfSectionIsCurrentRoute(item))?.title || '';
  }

  get menuItems() {
    return MENU_STRUCTURE(
      this.appService.app,
      this.args.profileCount,
      this.args.customAnswersCount,
      this.args.contentSuggestionsCount,
      this.aiAgentSetupService.isFinSetupChatRulesetLive ||
        this.aiAgentSetupService.isFinLiveInChannelOutsideSetup('chat')
        ? 'online'
        : 'offline',
      this.aiAgentSetupService.isFinSetupEmailRulesetLive ||
        this.aiAgentSetupService.isFinLiveInChannelOutsideSetup('email')
        ? 'online'
        : 'offline',
      this.aiAgentSetupService.isFinSetupPhoneRulesetLive ||
        this.aiAgentSetupService.isFinLiveInChannelOutsideSetup('phone')
        ? 'online'
        : 'offline',
    );
  }

  @action
  toggleSection(section: any) {
    if (section.url) {
      this.router.transitionTo(section.url);
      return;
    }

    let activeSection = this.getActiveSection();
    if (activeSection === section.title) {
      this.activeSection = this.activeSection === section.title ? '' : section.title;
    } else {
      this.secondaryOpenSection = this.secondaryOpenSection === section.title ? '' : section.title;
    }
  }

  @action
  isSectionActive(section: any) {
    let currentRouteName = this.router.currentRouteName;
    return currentRouteName === section.url;
  }

  @action
  isSectionOpen(section: any) {
    return this.activeSection === section.title || this.secondaryOpenSection === section.title;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAutomation::SubmenuNav': typeof SubmenuNav;
    'ai-automation/submenu-nav': typeof SubmenuNav;
  }
}
